import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SettingCard = ({ title, text, iconArrow, functionCard, enable }) => {
  return (
    <div
      onClick={functionCard}
      className={`card-setting-content setting-card d-flex align-items-center justify-content-between gap-3 w-100`}
      style={{
        cursor: enable ? "pointer" : "not-allowed",
        opacity: enable ? 1 : 0.6,
      }}
    >
      <div className="right-card-setting">
        <h2 className="title">{title}</h2>
        <p className="text">{text}</p>
      </div>
      <div className="arrow-left">
        <FontAwesomeIcon icon={iconArrow} />
      </div>
    </div>
  );
};

export default SettingCard;
