import { createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosClient from "Apis/axiosService";
import generalAxiosService from "./generalAxiosService";

const countriesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const InitialCountries = countriesAdapter.getInitialState();

const regionsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});
const InitialRegions = regionsAdapter.getInitialState();

const citiesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const InitialCities = regionsAdapter.getInitialState();

export const getCountries = createAsyncThunk(
  "general/getCountries",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/countries", {
        params,
      });
      return countriesAdapter.setAll(InitialCountries, response.data.data.data);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRegions = createAsyncThunk(
  "general/getRegions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/regions", {
        params,
      });
      return regionsAdapter.setAll(InitialRegions, response.data.data.data);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCities = createAsyncThunk(
  "general/getCities",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/cities", {
        params,
      });
      return citiesAdapter.setAll(InitialCities, response.data.data.data);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCanncelationReasons = createAsyncThunk(
  "general/getCanncelationReasons",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/cancellation_reasons", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getClientCanncelationReasons = createAsyncThunk(
  "general/getClientCanncelationReasons",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/client_cancellation_reasons", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const editCanncelationReasons = createAsyncThunk(
  "general/editCanncelationReasons",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(
        `/cancellation_reasons/${values.id}`,
        values,
        {
          params: { _method: "PUT" },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const editClientCanncelationReasons = createAsyncThunk(
  "general/editClientCanncelationReasons",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(
        `/client_cancellation_reasons/${values.id}`,
        values,
        {
          params: { _method: "PUT" },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const addCanncelationReasons = createAsyncThunk(
  "general/addCanncelationReasons",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/cancellation_reasons", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const addClientCanncelationReasons = createAsyncThunk(
  "general/addClientCanncelationReasons",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/client_cancellation_reasons", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const getTermConditions = createAsyncThunk(
  "general/getTermConditions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/term_conditions");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const editTermConditions = createAsyncThunk(
  "general/editTermConditions",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/term_conditions", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addCategory = createAsyncThunk(
  "general/addCategory",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/categories", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCategories = createAsyncThunk(
  "general/getCategories",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/categories", params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCategoryById = createAsyncThunk(
  "general/getCategoryById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get(`/categories/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCategory = createAsyncThunk(
  "general/updateCategory",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/categories/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteCategory = createAsyncThunk(
  "general/deleteCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.delete(`/categories/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getServiceCategories = createAsyncThunk(
  "general/getServiceCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/service_categories");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPromos = createAsyncThunk(
  "general/getPromos",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("promo_codes", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addPromo = createAsyncThunk(
  "general/addPromo",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/promo_codes`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePromo = createAsyncThunk(
  "general/updatePromo",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/promo_codes/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPromo = createAsyncThunk("general/getPromo", async (id, { rejectWithValue }) => {
  try {
    const response = await generalAxiosService.get(`/promo_codes/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendorWallet = createAsyncThunk(
  "general/getVendorWallet",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get(`/wallets`, {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAllPackages = createAsyncThunk(
  "general/getAllPackages",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("https://api.streamline.com.sa/admin/v1/packages", {
        params,
      });

      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePackageStatus = createAsyncThunk(
  "general/updatePackageStatus",
  async (values, { rejectWithValue }) => {
    try {
      const { id, ...rest } = values;
      console.log({ rest });
      const response = await axiosClient.post(
        `https://api.streamline.com.sa/admin/v1/packages/${id}`,
        rest,
        {
          params: { _method: "PUT" },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
