import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "Apis/axiosService";
import axios from "axios";
import vendorAxiosService from "./vendorAxiosService";

export const getEmployees = createAsyncThunk(
  "vendor/getEmployees",
  async (params, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`/employees`, {
        params,
        data: params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getServices = createAsyncThunk(
  "vendor/getServices",
  async (params, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get("/services", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateService = createAsyncThunk(
  "vendor/updateService",
  async (values, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post(`/services/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateEmployee = createAsyncThunk(
  "vendor/updateEmployee",
  async (params, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post(`/employees/${params.id}`, params.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addService = createAsyncThunk(
  "vendor/addService",
  async (values, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post("/services", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteEmployee = createAsyncThunk(
  "vendor/deleteEmployee",
  async (id, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.delete(`/employees/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getEmployee = createAsyncThunk(
  "vendor/getEmployee",
  async (id, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`/employees/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addEmployee = createAsyncThunk(
  "vendor/addEmployee",
  async (values, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post(`/employees`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBranches = createAsyncThunk(
  "vendor/getBranches",
  async (params, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get("/branches", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addSection = createAsyncThunk(
  "vendor/addSection",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post("/sections", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSections = createAsyncThunk(
  "vendor/getSections",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get("/sections");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getService = createAsyncThunk("vendor/getService", async (id, { rejectWithValue }) => {
  try {
    const response = await vendorAxiosService.get(`/services/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getBranch = createAsyncThunk("vendor/getBranch", async (id, { rejectWithValue }) => {
  try {
    const response = await vendorAxiosService.get(`/branches/${id}?with[0]=manager`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addBranch = createAsyncThunk(
  "vendor/addBranch",
  async (values, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post("/branches", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateBranch = createAsyncThunk(
  "vendor/updateBranch",
  async (requestBody, { rejectWithValue }) => {
    try {
      const { id, formDataObject } = requestBody;

      const form = new FormData();
      formDataObject.forEach((item, key) => {
        form.append(key, item);
      });

      const response = await vendorAxiosService.post(`/branches/${id}`, form, {
        params: { _method: "PUT" },
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleBranch = createAsyncThunk(
  "vendor/toggleBranch",
  async (values, { rejectWithValue }) => {
    try {
      const { id, isActive } = values;
      const response = await vendorAxiosService.post(
        `/branches/${id}`,
        {
          is_active: isActive,
        },
        {
          params: { _method: "PUT" },
        },
      );
      return response.data;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getOffers = createAsyncThunk(
  "vendor/getOffers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`/offers`, {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getOffer = createAsyncThunk("vendor/getOffer", async (id, { rejectWithValue }) => {
  try {
    const response = await vendorAxiosService.get(`/offers/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addOffer = createAsyncThunk("vendor/addOffer", async (values, { rejectWithValue }) => {
  try {
    const response = await vendorAxiosService.post("/offers", values);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateOffer = createAsyncThunk(
  "vendor/updateOffer",
  async (values, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post(`/offers/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSubscription = createAsyncThunk(
  "vendor/updateSubscription",
  async (values, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post(`/subscriptions/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSubscriptions = createAsyncThunk(
  "vendor/getSubscriptions",
  async (paramString, { rejectWithValue }) => {
    try {
      const { data } = await vendorAxiosService.get("/subscriptions?" + paramString);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSubscription = createAsyncThunk(
  "vendor/getSubscription",
  async (id, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`/subscriptions/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getHomeData = createAsyncThunk(
  "vendor/getHomeData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get("home_totals");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookingCountChart = createAsyncThunk(
  "vendor/getBookingCountChart",
  async (period, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`booking_count_chart?filter=${period}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookingTotalChart = createAsyncThunk(
  "vendor/getBookingTotalChart",
  async (period, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`booking_total_chart?filter=${period}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookingStatisticsMonth = createAsyncThunk(
  "vendor/getBookingStatisticsMonth",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`booking_count_with_month_chart?year=2024`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookingStatisticsWeek = createAsyncThunk(
  "vendor/getBookingStatisticsWeek",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`/booking_count_last_week_chart`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRegisterStatisticsMonth = createAsyncThunk(
  "vendor/getRegisterStatisticsMonth",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`/register_count_with_month_chart?year=2024`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const statiticsLastBookings = createAsyncThunk(
  "vendor/statiticsLastBookings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`last_bookings`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const statiticsLastCustomers = createAsyncThunk(
  "vendor/statiticsLastCustomers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`last_customers`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookings = createAsyncThunk(
  "vendor/getBookings",
  async (params, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get(`bookings`, {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBooking = createAsyncThunk("vendor/getBooking", async (id, { rejectWithValue }) => {
  try {
    const response = await vendorAxiosService.get(`booking_report_show/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendorCustomer = createAsyncThunk(
  "admin/getVendorCustomer",
  async (params, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get("/customer_report_list", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPosTotals = createAsyncThunk(
  "vendor/getPosTotals",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get("/pos_totals");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getVendorSettings = createAsyncThunk(
  "vendor/getVendorSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.get("/vendor_settings");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateVendorSettings = createAsyncThunk(
  "vendor/updateVendorSettings",
  async (values, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post("/vendor_settings", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addVendorPOSBooking = createAsyncThunk(
  "vendor/addVendorPOSBooking",
  async (values, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post("/bookings", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const GetWathqDetails = createAsyncThunk(
  "vendor/GetWathqDetails",
  async (taxNo, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://api.wathq.sa/v5/commercialregistration/fullinfo/${taxNo}`,
        {
          headers: {
            apiKey: "8EiUAjw6iyjM5l5uAlceILfWpJ1g3uEJ",
          },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const registerServiceProvider = createAsyncThunk(
  "vendor/registerServiceProvider",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post("https://api.streamline.com.sa/vendor/v1/register", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// generate booking vendor invoice
export const generateBookingVendorInvoice = createAsyncThunk(
  "vendor/generateBookingVendorInvoice",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post("/vendor/v1/booking_vendor_invoice", {
        booking_id: bookingId,
      });
      return response.data;
    } catch (err) {
      console.log(err.response);
      return rejectWithValue(err.response);
    }
  },
);

export const updateProfile = createAsyncThunk(
  "vendor/updateProfile",
  async (body, { rejectWithValue }) => {
    try {
      const response = await vendorAxiosService.post("/api/v1/update-profile", { ...body });
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  },
);

// {auto_renew: true, package_id: 1}
export const SubscripeToPackage = createAsyncThunk(
  "vendor/SubscripeToPackage",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/vendor/v1/subscriptions", values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const PaySubscription = createAsyncThunk(
  "vendor/PaySubscription",
  async (subscription_id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/vendor/v1/subscriptions/pay", { subscription_id });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const checkIsSubscriptionPaid = createAsyncThunk(
  "vendor/checkIsSubscriptionPaid",
  async (subscription_id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/vendor/v1/subscriptions/check_is_paid`, {
        subscription_id,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const getServicesReport = createAsyncThunk(
  "vendor/getServicesReport",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("vendor/v1/service_report_list", { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const getOffersReport = createAsyncThunk(
  "vendor/getOffersReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/vendor/v1/offer_report_list");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);
