import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { Link } from "react-router-dom";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import {
  faCalendar,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectField from "Components/Forms/SelectField";
import DatePicker from "react-datepicker";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import ImageUpload from "Dashboard/Components/UiDashboard/ImageUpload/ImageUpload";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getVendor } from "store/admin/adminActions";
import { unwrapResult } from "@reduxjs/toolkit";

const ProfileEditeAdd = () => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("ادخل الأسم الاول"),
    lastName: Yup.string().required("ادخل الأسم الاخير"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "يجب ان يكون ارقام")
      .min(10, "يجب أن لا يقل رقم الهاتف عن 10 ارقام")
      .max(12, "يجب ألا يتجاوز رقم الهاتف 12 رقاما")
      .required("رقم الهاتف مطلوب"),
    email: Yup.string().email("ايميل خطاء").required("هذا الحقل مطلوب"),
    country: Yup.string().required("اختار الدولة"),
    region: Yup.string().required("اختار المنطقة"),
    branches: Yup.string().required("عنوان الفرع"),
    role: Yup.string().required("اختار الدور"),
    password: Yup.string()
      .required("كلمة السر مطلوبة")
      .min(8, "يجب ان تكون كلمة السر اكثر من 8 احرف وارقام"),
    rePassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "كلمة السر غير متطابقة")
      .required("تأكيد كلمة السر"),
  });

  const initialValues = {
    first_name: "",
    laset_name: "",
    email: "",
    phone: "",
    country_id: "",
    region_id: "",
    role: "",
    branches: "",
    password: "",
    rePassword: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm();
  };

  // SHOW AND HIDE PASSWORD
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [formValues, setFormValues] = useState();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "rePassword") {
      setShowRePassword(!showRePassword);
    }
  };

  //   SELECT INPUT DATE
  const [startDate2, setStartDate2] = useState(null);

  const handleDateChange2 = (date) => {
    if (date) {
      setStartDate2(date);
    }
  };

  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchVendor = () => {
    dispatch(getVendor(id))
      .then(unwrapResult)
      .then((res) => {
        console.log(res.data.user, "?");
        setFormValues(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchVendor();
  }, []);

  return (
    <>
      <HelmetInfo titlePage={"اضافة تعديل مستخدم جديد"} />
      {/* ========= START HEADER TOP INFO ========= */}
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"حسابات مزود الخدمة"}
          titleInfoPage={"اضافة/ تعديل مستخدم جديد"}
          routeHomePage={"/dashboard/homedashboard"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/serviceprovideraccounts"
          >
            عودة
          </Link>
        </div>
      </div>
      {/* ========= END HEADER TOP INFO ========= */}
      {/* ========= START DETAILS ADD EDITE PROFILE ========= */}
      <div className="details-add-edite-PROFILE mt-3" data-aos="fade-up">
        <ImageUpload />
        {/* ======== START INFO USER EDIT ========= */}
        <div className="info-user-edit">
          {/* ========== START FORM FIELD ========= */}
          <FormField
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <div className="row g-1 g-sm-3">
              <div className="col-12 col-md-6">
                <InputFiled
                  label={"الاسم الأول"}
                  name="firstName"
                  type="text"
                  placeholder={"الاسم الأول"}
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <InputFiled
                  label={"الاسم الأخير"}
                  name="lastName"
                  type="text"
                  placeholder={"الاسم الأخير"}
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <InputFiled
                  label={"البريد الألكتروني"}
                  name="email"
                  type="email"
                  placeholder={"example@gmail.com"}
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <InputFiled
                  label={"رقم الجوال"}
                  name="phone"
                  type="number"
                  placeholder={"رقم الجوال"}
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <div className="date-birth">
                  <label
                    htmlFor="exampleFormControlInputBirh"
                    className="form-label"
                  >
                    تاريخ الميلاد
                  </label>
                  <div className="input-date-info">
                    <DatePicker
                      selected={startDate2}
                      onChange={handleDateChange2}
                      className="form-control"
                      placeholderText="تاريخ الميلاد"
                    />
                    <div className="input-icon">
                      <FontAwesomeIcon icon={faCalendar} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="select-reg">
                  <label
                    htmlFor="exampleFormControlInput2"
                    className="form-label"
                  >
                    الدولة
                  </label>
                  <SelectField
                    name="country"
                    label={false}
                    options={[
                      { value: "option1", label: "Option 1" },
                      { value: "option2", label: "Option 2" },
                      { value: "option3", label: "Option 3" },
                    ]}
                    valueSelected={"الدولة"}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="select-reg">
                  <label
                    htmlFor="exampleFormControlInput22"
                    className="form-label"
                  >
                    المنطقة
                  </label>
                  <SelectField
                    name="region"
                    label={false}
                    options={[
                      { value: "option1", label: "Option 1" },
                      { value: "option2", label: "Option 2" },
                      { value: "option3", label: "Option 3" },
                    ]}
                    valueSelected={"المنطقة"}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="select-reg">
                  <label
                    htmlFor="exampleFormControlInputRole"
                    className="form-label"
                  >
                    الدور
                  </label>
                  <SelectField
                    name="role"
                    label={false}
                    options={[
                      { value: "option1", label: "Option 1" },
                      { value: "option2", label: "Option 2" },
                      { value: "option3", label: "Option 3" },
                    ]}
                    valueSelected={"الدور"}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="select-reg">
                  <label
                    htmlFor="exampleFormControlInputBranch"
                    className="form-label"
                  >
                    الفروع
                  </label>
                  <SelectField
                    name="branches"
                    label={false}
                    options={[
                      { value: "option1", label: "Option 1" },
                      { value: "option2", label: "Option 2" },
                      { value: "option3", label: "Option 3" },
                    ]}
                    valueSelected={"الفروع"}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="input-pass position-relative">
                  <InputFiled
                    label="كلمة المرور"
                    name="password"
                    placeholder=""
                    success
                    type={showPassword ? "text" : "password"}
                  />

                  <button
                    className="icon-eye-button"
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="input-pass position-relative">
                  <InputFiled
                    label="تاكيد كلمة المرور "
                    name="rePassword"
                    placeholder="تأكيد كلمة المرور"
                    success
                    type={showRePassword ? "text" : "password"}
                  />

                  <button
                    className="icon-eye-button"
                    onClick={() => togglePasswordVisibility("rePassword")}
                  >
                    {showRePassword ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <button type="submit" className="main-btn btn-submit px-5 mt-4">
              حفظ
            </button>
          </FormField>
          {/* ========== END FORM FIELD ========= */}
        </div>
        {/* ======== END INFO USER EDIT ========= */}
      </div>
      {/* ========= END DETAILS ADD EDITE PROFILE ========= */}
    </>
  );
};

export default ProfileEditeAdd;
