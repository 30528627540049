import { unwrapResult } from "@reduxjs/toolkit";
import { baseURL } from "Apis/axiosService";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getCities, getCountries, getRegions } from "store/general/generalActions";
import { getBranch } from "store/vendor/vendorActions";
import imgUser from "../../../assets/images/userImg/user.png";
import "./Branches.css";

const BranchData = () => {
  const [branch, setBranch] = useState(null);
  const cities = useSelector((state) => state.general.cities);
  const regions = useSelector((state) => state.general.regions);
  const countries = useSelector((state) => state.general.countries);
  const isLoading = useSelector((state) => state.vendor.isLoading);

  const infoBasic = [
    { id: 0, title: "اسم الفرع", getValue: () => branch?.name },
    {
      id: 1,
      title: "موظفى الفرع",
      getValue: () =>
        branch?.employees?.map((employee) => (
          <li key={`employee-${employee.id}`} className="d-flex  align-items-center  gap-3">
            <img src={`${baseURL}/${employee.image}`} alt={employee.name} width={"35px"} height={"35px"} />
            <span>{employee.name}</span>
          </li>
        )),
    },
    {
      id: 2,
      title: "عنوان الفرع",
      getValue: () => branch?.address,
    },
    {
      id: 3,
      title: "الدولة",
      getValue: () => cities[branch?.country_id]?.name_ar,
    },
    {
      id: 4,
      title: "المدينة",
      getValue: () => cities[branch?.city_id]?.name_ar,
    },
    {
      id: 5,
      title: "الموقع على الخريطة",
      getValue: () => branch?.lat,
    },
    {
      id: 6,
      title: "ساعات العمل الرسمية",
      getValue: () =>
        branch?.official_hours?.map((time) => (
          <li key={`employee-${time.id}`} className="d-flex  align-items-center  gap-3">
            <span>{time.start_time}</span>
            <span>-</span>
            <span>{time.start_time}</span>
          </li>
        )),
    },
    {
      id: 7,
      title: "اظهار تقيمات الموظفين",
      getValue: () => (branch?.show_rate === "1" ? "لا" : "نعم"),
    },
  ];
  const imgBranch = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }];
  const { id } = useParams();
  const dispatch = useDispatch();

  const fetchBranch = () => {
    dispatch(getBranch(id))
      .then(unwrapResult)
      .then((res) => {
        setBranch(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchBranch();
  }, [id]);

  useEffect(() => {
    if (!Object.keys(regions)?.length) {
      dispatch(getRegions());
    }
  }, [regions, dispatch]);

  useEffect(() => {
    if (!Object.keys(cities)?.length) {
      dispatch(getCities());
    }
  }, [cities, dispatch]);

  useEffect(() => {
    if (!Object.keys(countries)?.length) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  return (
    <>
      <HelmetInfo titlePage={"التفاصيل"} />
      <div
        className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3"
        data-aos="fade-left"
      >
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          routeHomePage={"/dashboard/vendor/homedashboard"}
          titlePage={"الرئيسية"}
          titleInfoPage={"التفاصيل"}
        />
        <div className="link-back">
          <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/vendor/branches">
            عودة
          </Link>
        </div>
      </div>
      <div className="basic-information-content account-info-details mt-3" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="all-basic-info all-details-branch">
            <div className="header-info-profile ">
              <div className="info-data-user d-flex  justify-content-center  align-items-center  gap-3 flex-column  text-center  mb-3">
                <div className="image-user">
                  <img src={imgUser} alt="img user" className=" object-fit-cover " width="85px" height={"85px"} />
                </div>
                <div className="content-data">
                  <h2 className="name-user">{branch?.manager.name}</h2>
                  <p className="title-user">مدير الفرع</p>
                </div>
              </div>

              <div className="content-user-info">
                {infoBasic.map((item) => {
                  return (
                    <div className="all-info-content  d-flex" key={item.id}>
                      {/* ========= START MAIN INFO ONE ========= */}
                      <div className="main-info-one d-flex  align-items-center">
                        <h2 className="title">{item.title}</h2>
                      </div>
                      {/* ========= END MAIN INFO ONE ========= */}
                      {/* ========= START MAIN INFO ONE ========= */}
                      <div className="main-info-one d-flex  align-items-center">
                        <p className="text">{item.getValue()}</p>
                        {item.id === 1 && (
                          <ul className="m-0 p-0 d-flex  align-items-center flex-wrap   gap-3">
                            {item.getValue()}
                            {/* {item.info.map((employee) => (
                            <li
                              key={employee.id}
                              className="d-flex  align-items-center  gap-3"
                            >
                              <img
                                src={employee.img}
                                alt={employee.name}
                                width={"35px"}
                                height={"35px"}
                              />
                              <span>{employee.name}</span>
                            </li>
                          ))} */}
                          </ul>
                        )}
                      </div>
                      {/* ========= END MAIN INFO ONE ========= */}
                    </div>
                  );
                })}
              </div>
              <div className="bottom-details-branch mt-4">
                <div className="details-branch-one d-flex  flex-column  gap-3  mb-3">
                  <h2 className="title">صورة الفرع البارزة</h2>
                  <div className="img-branch">
                    <img src={branch?.photo} alt="img branch" width={"121px"} height={"91px"} />
                  </div>
                </div>
                <div className="all-bottom-details">
                  <h2 className="title">الصور الآخري</h2>
                  <div className="images-branches d-flex  align-items-center  gap-3 flex-wrap  mt-3">
                    {branch?.images?.map((itemImg) => {
                      return (
                        <div className="img-branch" key={itemImg.id}>
                          <img src={itemImg.photo} alt="img branch" width={"121px"} height={"91px"} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BranchData;
