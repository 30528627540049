import {
  faChevronDown,
  faChevronUp,
  faHandsHelping,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import icon01 from "../../../assets/images/dashboardImages/iconsSideBar/01.svg";
import icon010 from "../../../assets/images/dashboardImages/iconsSideBar/010.svg";
import icon011 from "../../../assets/images/dashboardImages/iconsSideBar/011.svg";
import icon013 from "../../../assets/images/dashboardImages/iconsSideBar/013.svg";
import icon014 from "../../../assets/images/dashboardImages/iconsSideBar/014.svg";
import icon015 from "../../../assets/images/dashboardImages/iconsSideBar/015.svg";
import icon016 from "../../../assets/images/dashboardImages/iconsSideBar/016.svg";
import icon017 from "../../../assets/images/dashboardImages/iconsSideBar/017.svg";
import icon018 from "../../../assets/images/dashboardImages/iconsSideBar/018.svg";
import icon019 from "../../../assets/images/dashboardImages/iconsSideBar/019.svg";
import icon020 from "../../../assets/images/dashboardImages/iconsSideBar/020.svg";
import icon021 from "../../../assets/images/dashboardImages/iconsSideBar/021.svg";
import icon03 from "../../../assets/images/dashboardImages/iconsSideBar/03.svg";
import icon05 from "../../../assets/images/dashboardImages/iconsSideBar/05.svg";
import icon06 from "../../../assets/images/dashboardImages/iconsSideBar/06.svg";
import icon07 from "../../../assets/images/dashboardImages/iconsSideBar/07.svg";
import icon08 from "../../../assets/images/dashboardImages/iconsSideBar/08.svg";
import icon09 from "../../../assets/images/dashboardImages/iconsSideBar/09.svg";
import logo from "../../../assets/images/dashboardImages/logo/logo.svg";
import "./SideNavBar.css";

const SideNavBar = ({ isOpen, closeToggleSidebar }) => {
  const userRole = useSelector((state) => state.auth.role);
  const isAdmin = userRole === "admin";
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownContentRef = useRef(null);

  const ADMIN_LINKS = [
    {
      text: "الرئيسية",
      icon: icon01,
      path: "homedashboard",
      toggleSidebar: closeToggleSidebar,
    },
    // {
    //   text: "التقارير",
    //   icon: icon03,
    //   path: "statisticsreports",
    //   toggleSidebar: closeToggleSidebar,
    // },
    // {
    //   text: "الأدوار والصلاحيات",
    //   icon: icon014,
    //   path: "rolespowers",
    //   toggleSidebar: closeToggleSidebar,
    // },
    {
      text: "حسابات مزودي الخدمة",
      icon: icon05,
      path: "serviceprovideraccounts",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "حسابات العملاء",
      icon: icon05,
      path: "accountsusers",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الحجوزات",
      icon: icon011,
      path: "bookingsDashboard",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الباقات",
      icon: icon05,
      path: "packages",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الاشتراكات",
      icon: icon06,
      path: "serviceprovidersubscriptions",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "فئات الخدمات",
      icon: icon014,
      path: "servicesCategories",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الخدمات",
      icon: icon010,
      path: "servicespage",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "العروض",
      icon: icon014,
      path: "offersInfo",
      toggleSidebar: closeToggleSidebar,
    },

    // {
    //   text: "الأعدادات",
    //   icon: icon016,
    //   path: "settings",
    //   toggleSidebar: closeToggleSidebar,
    // },
  ];

  const VENDOR_LINKS = [
    {
      text: "الرئيسية",
      icon: icon01,
      path: "homedashboard",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "التقويم",
      icon: icon020,
      path: "calendar",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الأحصائيات والتقارير",
      icon: icon03,
      path: "statisticsreports",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "حسابات العملاء",
      icon: icon05,
      path: "accountsusers",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "اشتراك مزود الخدمة",
      icon: icon06,
      path: "providersubscription",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الفروع",
      icon: icon07,
      path: "branches",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "المحفظة",
      icon: icon08,
      path: "wallet",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الموظفين",
      icon: icon09,
      path: "employees",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الخدمات",
      icon: icon010,
      path: "servicespage",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الحجوزات",
      icon: icon011,
      path: "bookingsDashboard",
      toggleSidebar: closeToggleSidebar,
    },
    // {
    //   text: "التقيمات",
    //   icon: icon021,
    //   path: "ratespage",
    //   toggleSidebar: closeToggleSidebar,
    // },
    {
      text: "الفاتورة الضريبية",
      icon: icon013,
      path: "taxinvoices",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "العروض",
      icon: icon014,
      path: "offersInfo",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "اكواد الخصم",
      icon: icon015,
      path: "discountCodes",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الاعدادات",
      icon: icon016,
      path: "settings",
      toggleSidebar: closeToggleSidebar,
    },
  ];

  const navBarLinks = isAdmin ? ADMIN_LINKS : VENDOR_LINKS;

  const listDrop = [
    {
      text: "المساعدة",
      icon: icon017,
      path: "helpcenter",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "تواصل معنا",
      icon: icon018,
      path: "contactUs",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الأسئلة الشائعة",
      icon: icon019,
      path: "commonFaq",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "الشروط والأحكام",
      icon: icon020,
      path: "termsConditionsDash",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: "سياسة الخصوصية",
      icon: icon021,
      path: "privacyPolicy",
      toggleSidebar: closeToggleSidebar,
    },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (dropdownContentRef.current) {
      dropdownContentRef.current.style.height = isDropdownOpen
        ? `${dropdownContentRef.current.scrollHeight}px`
        : "0";
    }
  }, [isDropdownOpen]);

  return (
    <>
      <div className={`all-sidebar ${isOpen ? "open" : ""}`}>
        <div className={`overlay-bg-1 ${isOpen ? "open" : ""}`} onClick={closeToggleSidebar}></div>
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="icon-close" onClick={closeToggleSidebar}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Link
            to={`/${userRole === "admin" ? "dashboard/admin/homedashboard" : "dashboard/vendor/homedashboard"}`}>
            <div className="logo-sidebar">
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <ul className="navbar-nav">
            {navBarLinks.map((link) => (
              <li className="nav-item" key={link.path}>
                <NavLink
                  className={`nav-link ${link.newClass}`}
                  to={link.path}
                  onClick={link.toggleSidebar}>
                  <div
                    className="icon-link-nav img-bg"
                    style={{ backgroundImage: `url(${link.icon})` }}></div>
                  <span className="text-link d-flex"> {link.text}</span>
                </NavLink>
              </li>
            ))}

            {userRole.type === "vendor" && (
              <li className="nav-item">
                <div className="sidebar-item" onClick={toggleDropdown}>
                  <div className="btn-toggle d-flex align-items-center gap-2 justify-content-between">
                    <div className="icon-help d-none">
                      <FontAwesomeIcon icon={faHandsHelping} />
                    </div>
                    <div className="text-link"> مركز الدعم والمساعدة</div>
                    <div className="icon-arrow-btn">
                      <FontAwesomeIcon
                        className="icon-i"
                        icon={isDropdownOpen ? faChevronUp : faChevronDown}
                      />
                    </div>
                  </div>
                  <div className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
                    <div className="dropdown-content" ref={dropdownContentRef}>
                      {listDrop.map((link, idx) => (
                        <NavLink
                          key={`id-${idx}`}
                          className={`nav-link ${link.newClass}`}
                          to={link.path}
                          onClick={link.toggleSidebar}>
                          <div
                            className="icon-link-nav img-bg"
                            style={{ backgroundImage: `url(${link.icon})` }}></div>
                          <span className="text-link d-flex"> {link.text}</span>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideNavBar;
