import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHomeData } from "store/vendor/vendorActions";
import Charts from "./HomePages/HomeIndex/Charts/Charts";
import HomeIndex from "./HomePages/HomeIndex/HomeIndex";
import InfoDetailsBookings from "./HomePages/HomeIndex/InfoDetailsBookings";

const HomeDashobard = () => {
  const role = useSelector((state) => state.auth.role);
  const isLoading = useSelector((state) => state.admin.isLoading);
  const dispatch = useDispatch();
  const [infoDetailsBookings, setInfoDetailsBookings] = useState();

  const getAdminHomePageData = async () => {
    dispatch(getHomeData())
      .then(unwrapResult)
      .then((res) => {
        setInfoDetailsBookings(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getVendorHomePage = async () => {
    dispatch(getHomeData())
      .then(unwrapResult)
      .then((res) => {
        setInfoDetailsBookings(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (role === "vendor") {
      getVendorHomePage();
    }

    if (role === "admin") {
      getAdminHomePageData();
    }
  }, [role]);

  return (
    <>
      <HelmetInfo titlePage={"الرئيسية"} />
      <HomeIndex />

      {/* {role === "vendor" && <HeaderHomeIndex />} */}
      <div className="content-info-screen">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <InfoDetailsBookings infoDetailsBookings={infoDetailsBookings} />
            <Charts />
            {/* <TableData /> */}
          </>
        )}
      </div>
    </>
  );
};

export default HomeDashobard;
