import axios from "axios";

export const baseURL = "https://api.streamline.com.sa/";
const axiosClient = axios.create({
  baseURL,
});

// Set the Authorization header for all requests
axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosClient;
