import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { Link } from "react-router-dom";
import "./StatisticsReports.css";

const ReportCard = ({ id, title, icon, role }) => {
  return (
    <Link to={`/dashboard/${role}/statisticsreports/${id}`} className="report-card">
      <div className="report-icon">
        <FontAwesomeIcon icon={icon} color="#000000" />
      </div>
      <h3>{title}</h3>
    </Link>
  );
};

const StatisticsReports = () => {
  const user = useGetAuthUser();
  const role = user.type;

  const reports = [
    { id: "services", name: "services", title: "تقارير الخدمات", icon: faList },
    { id: "offers", name: "offers", title: "تقارير العروض", icon: faList },
    { id: "packages", name: "packages", title: "تقارير الباقات", icon: faList },
    // { id: "products", name: "products", title: "تقارير المنتجات", icon: faList },
    { id: "customers", name: "customers", title: "تقارير العملاء", icon: faList },
    { id: "employees", name: "employees", title: "تقارير الموظفين", icon: faList },
    { id: "financial", name: "financial", title: "تقارير مالية", icon: faList },
    // { id: "sales", name: "sales", title: "تقارير المبيعات", icon: faList },
  ];

  if (!role) return null;

  return (
    <section>
      <HelmetInfo titlePage="تقارير" />
      <HeaderPageInfo title={"تقارير"} />

      <div className="statistics-reports-container">
        <div className="reports-grid">
          {reports.map((report) => (
            <ReportCard
              key={report.id}
              id={report.id}
              name={report.name}
              title={report.title}
              icon={report.icon}
              role={role}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatisticsReports;
