import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { Tab, Tabs } from "react-bootstrap";
import imgBg from "../../../assets/images/main/04.png";
import imgUser from "../../../assets/images/userImg/user.png";
import BasicData from "./BasicData";
import ChangePassword from "./ChangePassword";
import "./ProfileDashboard.css";

const ProfileDashboard = () => {
  // const user = useGetAuthUser();

  return (
    <>
      <HelmetInfo titlePage={"الملف الشخصى"} />
      <div className="profile-page-info profile-page-info-dash mt-4 mb-3">
        <div
          className="header-page-profile img-bg"
          style={{ backgroundImage: `url(${imgBg})` }}></div>

        <div className="all-info-profile-content" data-aos="fade-up">
          <div className="header-profile">
            <div className="img-user">
              <img src={imgUser} width={"90px"} height={"90px"} alt="img user" />
            </div>
            {/* <Link to="##" className="icon-edit">
              <FontAwesomeIcon className="icon" icon={faEdit} />
            </Link> */}
          </div>

          <Tabs
            defaultActiveKey="basicInformation"
            id="uncontrolled-tab-example"
            className="mb-3 tabs-profile">
            <Tab className="tab-one" eventKey="basicInformation" title="البيانات الأساسية">
              <BasicData />
            </Tab>
            <Tab className="tab-one" eventKey="passwords" title="كلمة المرور">
              <ChangePassword />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
