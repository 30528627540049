import { useField } from "formik";

const SelectField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (e) => {
    const value = e.target.value;
    helpers.setValue(value);
  };

  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>{label}</label>
      <select
        {...field}
        {...props}
        onChange={handleChange}
        className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""}`}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
};

export default SelectField;
