import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getEmployee } from "store/vendor/vendorActions";
import { formatDateToArabic } from "utlis";
import imgUser from "../../../assets/images/userImg/user.png";

const DataEmployee = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    id: "",
    full_name: "",
    branches: [],
    breakHours: [],
    email: "",
    end_date: "",
    officialHours: [],
    phone: "",
    salary: "",
    services: [],
    start_date: "",
    noOfBookings: "",
    image: "",
  });

  const fetchDetails = () => {
    setIsDataLoading(true);
    dispatch(getEmployee(id))
      .then(unwrapResult)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          setEmployeeData({
            id: data.id,
            full_name: `${data.user?.first_name} ${data.user?.last_name}`,
            branches: data.branches.map((item) => item.name).join(", "),
            breakHours: data.break_hours.join(", ") || [],
            email: data.email || "",
            end_date: formatDateToArabic(data.end_date),
            officialHours: data.official_hours
              .map((item) => `يوم ${item.day} من ${item.start_time} : ${item.end_time}`)
              .join(", "),
            phone: data.phone || "",
            salary: data.salary,
            services: data.services?.map((item) => item.name_ar).join(", "),
            start_date: formatDateToArabic(data.start_date),
            noOfBookings: data.bookings?.length || 0,
            image: data.user.photo,
          });
        }
      })
      .catch((error) => console.log(error, "error"))
      .finally(() => setIsDataLoading(false));
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [id]);

  const renderInfoItem = (title, value) => (
    <div className="all-info-content d-flex">
      <div className="main-info-one d-flex align-items-center">
        <h2 className="title">{title}</h2>
      </div>
      <div className="main-info-one d-flex align-items-center">
        <p className="text">{value}</p>
      </div>
    </div>
  );

  return (
    <>
      <HelmetInfo titlePage={"بيانات الموظف"} />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/homedashboard"}
          titleInfoPage={"بيانات الموظف"}
        />
        <div className="link-back">
          <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/vendor/employees">
            عودة
          </Link>
        </div>
      </div>
      <div className="basic-information-content account-info-details mt-3" data-aos="fade-up">
        {isDataLoading ? (
          <Loader />
        ) : (
          <div className="all-basic-info">
            <div className="header-info-profile">
              <div className="image-user d-flex justify-content-center align-items-center mb-5">
                <img
                  src={employeeData.image || imgUser}
                  alt="img user"
                  className="object-fit-cover"
                  width="85px"
                  height="85px"
                />
              </div>
              <div className="all-data-content">
                <div className="row g-3">
                  <div className="col-12 col-md-12">
                    <div className="content-user-info">
                      {renderInfoItem("الاسم بالكامل", employeeData.full_name)}
                      {renderInfoItem("رقم الجوال", employeeData.phone)}
                      {renderInfoItem("تاريخ توظيف الموظف", employeeData.start_date)}
                      {renderInfoItem("الخدمات التى يقدمها الموظف", employeeData.services)}
                      {/* Rating section */}
                      {renderInfoItem("ساعات العمل", employeeData.officialHours)}
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="content-user-info">
                      {renderInfoItem("البريد الالكترونى", employeeData.email)}
                      {renderInfoItem("الراتب", `${employeeData.salary} ريال`)}
                      {renderInfoItem("الفرع", employeeData.branches)}
                      {renderInfoItem("عدد الحجوزات", `${employeeData.noOfBookings} حجز`)}
                      {renderInfoItem("عدد التقييمات", "1500 تقييم")}
                      {renderInfoItem("وقت الراحة", employeeData.breakHours)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DataEmployee;
