// @ts-nocheck
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getServices, getVendors } from "store/admin/adminActions";
import "./ServicesPage";

const AdminServicesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [vendors, setVendors] = useState({});
  const [paginationData, setPaginationData] = useState({
    currentPageItemCounts: 0,
    totalPagesCount: 0,
    totalItemsCount: 0,
    limit: 10,
    currentPage: 1,
  });
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    async (fetchFunction, params, dataHandler) => {
      setIsLoading(true);
      try {
        const result = await dispatch(fetchFunction(params)).then(unwrapResult);
        dataHandler(result.data);
        setPaginationData((prevState) => ({
          ...prevState,
          currentPageItemCounts: result.data.data?.length || 0,
          totalItemsCount: result.data.total || 0,
          totalPagesCount: result.data.links.length - 2 || 0,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  const handleServicesData = useCallback((data) => {
    setServices(data.data);
  }, []);

  const handleVendorsData = useCallback((data) => {
    setVendors(
      data.data.reduce((acc, curr) => {
        acc[curr.id] = { name_ar: curr.name, name_en: curr.name };
        return acc;
      }, {}),
    );
  }, []);

  useEffect(() => {
    const { limit, currentPage } = paginationData;
    fetchData(getVendors, { per_page: 1000, page: currentPage, search }, handleVendorsData);
    fetchData(getServices, { per_page: limit, page: currentPage, search }, handleServicesData);
  }, [
    fetchData,
    paginationData.limit,
    paginationData.currentPage,
    search,
    handleServicesData,
    handleVendorsData,
  ]);

  const renderTableRow = useCallback(
    (item) => (
      <tr key={`service-${item.id}`}>
        <td>{item.name_ar}</td>
        <td>{vendors[item.vendor_id]?.name_ar}</td>
        {!item.isEmpty && (
          <td>
            <IconsTable
              typeIcon1={faEye}
              functionEye={() => navigate(`servicesDetails/${item.id}`)}
              isDeleteIcon={false}
              functionDelete={false}
              typeIcon3={false}
            />
          </td>
        )}
      </tr>
    ),
    [vendors, navigate],
  );

  return (
    <>
      <HelmetInfo titlePage="الخدمات" />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title="الخدمات"
          isShowLeftContent={true}
          contentPageLeft={
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <SearchHeaderPage onSearchChange={setSearch} />
            </div>
          }
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="table-content mt-3 table-content-2">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">اسم الخدمة</th>
                  <th scope="col">اسم مزود الخدمة</th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={<>{services.map(renderTableRow)}</>}
            />
            {services.length === 0 && (
              <p className="mx-auto w-100 text-center">لا يوجد عناصر لإظهارها</p>
            )}
          </div>
          <Pagination
            {...paginationData}
            setLimit={(limit) => setPaginationData((prev) => ({ ...prev, limit }))}
            setCurrentPage={(currentPage) =>
              setPaginationData((prev) => ({ ...prev, currentPage }))
            }
            limitOptions={[10, 20, 30, 40]}
          />
        </>
      )}
    </>
  );
};

export default AdminServicesPage;
