import { unwrapResult } from "@reduxjs/toolkit";
import HomeInfo from "Components/HomeInfo/HomeInfo";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchDetails } from "store/customer/customerActions";
import { extractOffers } from "utlis";

const Home = () => {
  const storeServicesList = useSelector((state) => state.customer.servicesList);
  const storeServiceProviders = useSelector((state) => state.customer.serviceProviders);

  const [servicesList, setServicesList] = useState([]); //for home page display
  const [serviceProviders, setServiceProviders] = useState([]); // for home page display
  const [offers, setOffers] = useState([]); // for home page display
  const [isLoading, setIsLoading] = useState(false); // for home page display

  useEffect(() => {
    if (storeServicesList.length && storeServiceProviders.length) {
      setServicesList(storeServicesList);
      setServiceProviders(storeServiceProviders);
      setOffers(extractOffers(storeServiceProviders, storeServicesList));
      setIsLoading(false);
    }
  }, [storeServicesList, storeServiceProviders]);

  const dispatch = useDispatch();
  const searchProviders = useCallback(
    async (params) => {
      // console.log({ params });
      setIsLoading(true);
      const response = await dispatch(getSearchDetails(params));
      const result = unwrapResult(response);
      const vendors = result.data.vendors.filter((vendor) => vendor.is_active === "1");
      const services = result.data.services.filter((service) => service.is_active === "1");
      setServicesList(services);
      setServiceProviders(vendors);
      setOffers(extractOffers(vendors, services));
      setIsLoading(false);
    },
    [dispatch],
  );

  return (
    <div>
      <HomeInfo
        serviceProviders={serviceProviders}
        servicesList={servicesList}
        offers={offers}
        searchProviders={searchProviders}
        isLoading={isLoading}
      />{" "}
    </div>
  );
};

export default Home;
