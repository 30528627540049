import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { logoutAction } from "store/auth/authActions";

const useGetAuthUser = () => {
  const { user } = useSelector((state) => state.auth);
  const localUser = localStorage.getItem("user");
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (localUser && JSON.parse(localUser).type !== user.type) {
      dispatch(logoutAction());
    }

    if (pathname.includes("/dashboard/admin") && user.type !== "admin") {
      dispatch(logoutAction());
    }

    if (pathname.includes("/dashboard/vendor") && user.type !== "vendor") {
      dispatch(logoutAction());
    }
  }, [localUser, dispatch, user, pathname]);

  return user;
};

export default useGetAuthUser;
