import { unwrapResult } from "@reduxjs/toolkit";
import Footer from "Components/Footer/Footer";
import NavBar from "Components/NavBar/NavBar";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { logoutAction } from "store/auth/authActions";
import { getSearchDetails, listWishList } from "store/customer/customerActions";
import {
  setCategories,
  setEmployees,
  setOffers,
  setServiceProviders,
  setServicesList,
  setVendorsBranches,
  setWishList,
} from "store/customer/customerSlice";
import { extractBranchesFromVendors, extractEmployees, extractOffers } from "utlis";
import { getCategories } from "../../store/general/generalActions";

const Layout = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLogedIn);
  const role = useSelector((state) => state.auth.role);

  const fetchCustomerDetails = useCallback(() => {
    dispatch(getSearchDetails())
      .then(unwrapResult)
      .then((result) => {
        const vendors = result.data.vendors.filter((vendor) => vendor.is_active === "1");
        console.log("vendors");
        console.log(vendors);

        const services = result.data.services.filter((service) => service.is_active === "1");
        console.log("services");
        console.log(services);

        dispatch(setServiceProviders(vendors));
        dispatch(setServicesList(services));

        const employees = extractEmployees(services);
        dispatch(setEmployees(employees));

        const offers = extractOffers(vendors, services);
        dispatch(setOffers(offers));

        const branches = extractBranchesFromVendors(vendors);
        dispatch(setVendorsBranches(branches));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  const getWishlist = useCallback(() => {
    const params = {
      with: ["vendor"],
      limit: 20,
      offset: 0,
      sort: "DESC",
      paginate: "true",
      field: "id",
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name_ar"],
    };
    dispatch(listWishList(params))
      .unwrap()
      .then((res) => {
        dispatch(setWishList(res.data.data));
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [dispatch]);

  const getCategoriesList = useCallback(() => {
    dispatch(getCategories())
      .unwrap()
      .then((res) => {
        dispatch(setCategories(res.data.data));
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchCustomerDetails();
    getWishlist();
    getCategoriesList();

    const notCustomer = role !== "customer";
    if (isLoggedIn && notCustomer) {
      dispatch(logoutAction());
    }
  }, [isLoggedIn, role, fetchCustomerDetails, dispatch]);

  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
