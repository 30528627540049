// @ts-nocheck
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getVendor } from "store/admin/adminActions";
import { getCountries, getRegions } from "store/general/generalActions";
import imgTicket from "../../../assets/images/dashboardImages/icons/ticket3.svg";
import imgUser from "../../../assets/images/userImg/user.png";

const DetailsDataUsers = () => {
  const infoBasic = [
    { id: 0, title: "الاسم بالكامل", key: "first_name" },
    { id: 1, title: "رقم الجوال", key: "phone" },
    { id: 2, title: "البريد الالكترونى", key: "email" },
  ];

  const [userInfo, setUserInfo] = useState(null);
  const isLoading = useSelector((state) => state.admin.isLoading);
  const countries = useSelector((state) => state.general.countries);
  const cities = useSelector((state) => state.general.cities);
  const regions = useSelector((state) => state.general.regions);

  const { id } = useParams();

  const dispatch = useDispatch();

  const fetchVendor = () => {
    dispatch(getVendor(id))
      .then(unwrapResult)
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (id) {
      fetchVendor();
    }
  }, [id]);

  useEffect(() => {
    if (!Object.keys(regions)?.length) {
      dispatch(getRegions());
    }
  }, [regions, dispatch]);

  useEffect(() => {
    if (!Object.keys(countries)?.length) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  return (
    <>
      <HelmetInfo titlePage={"التفاصيل"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/admin/homedashboard"}
          titleInfoPage={"التفاصيل"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/serviceprovideraccounts">
            عودة
          </Link>
        </div>
      </div>
      <div className="basic-information-content account-info-details mt-3" data-aos="fade-up">
        {/* =========== START ALL BASIC INFO ============= */}
        <div className="all-basic-info">
          {/* =========== START TABS RATE ============ */}
          <div className="tabs-rate mt-4">
            <div className="header-info-profile  ">
              {/* ============ START TABS CONTENT ========== */}
              {isLoading ? (
                <Loader />
              ) : (
                <Tabs
                  defaultActiveKey="basicInfo"
                  id="uncontrolled-tab-example"
                  className="tabs-rates-content">
                  {/* ============ START TAB ONE ============ */}
                  <Tab className="tab-one" eventKey="basicInfo" title="المعلومات الرئيسيه">
                    {/* ======== START HEADER INFO ========== */}

                    <div className="image-user d-flex  justify-content-center  align-items-center mb-5 mt-3">
                      <img
                        src={imgUser}
                        alt="img user"
                        className=" object-fit-cover "
                        width="85px"
                        height={"85px"}
                      />
                    </div>

                    <div className="content-user-info">
                      {infoBasic.map((item) => {
                        return (
                          <div className="all-info-content  d-flex" key={item.id}>
                            {/* ========= START MAIN INFO ONE ========= */}
                            <div className="main-info-one d-flex  align-items-center">
                              <h2 className="title">{item.title}</h2>
                            </div>
                            {/* ========= END MAIN INFO ONE ========= */}
                            {/* ========= START MAIN INFO ONE ========= */}
                            <div className="main-info-one d-flex  align-items-center">
                              <p className="text">{userInfo?.user[item.key]}</p>
                            </div>
                            {/* ========= END MAIN INFO ONE ========= */}
                          </div>
                        );
                      })}
                    </div>

                    {/* ======== END HEADER INFO ========== */}
                  </Tab>

                  {/* SERVICES */}
                  <Tab className="tab-one" eventKey="services" title="الخدمات">
                    <div className="table-content mt-3 table-content-2">
                      <TableDataInfo
                        titleTableHeader={false}
                        isHashTrue={false}
                        hashId={false}
                        dateThead={
                          <>
                            <th scope="col">اسم الخدمة</th>
                            <th scope="col">الوصف</th>
                            <th scope="col">حالة الخدمة</th>
                            {/* <th scope="col">القسم</th> */}
                            <th scope="col">السعر</th>
                            <th scope="col">خدمة مميزة</th>
                          </>
                        }
                        dataTbody={
                          <>
                            {userInfo?.services.map((service) => {
                              return (
                                <tr key={service.id}>
                                  <td>{service.name_ar}</td>
                                  <td>{service.description_ar}</td>
                                  <td>
                                    <p className={service.colorStatus}>
                                      {service.is_active ? "مفعل" : "غير مفعل"}
                                    </p>
                                  </td>
                                  {/* <td>{service.sectionServ}</td> */}
                                  <td>{service.price}</td>
                                  <td>{service.featured ? "مميز" : "غير مميز"}</td>
                                </tr>
                              );
                            })}
                          </>
                        }
                      />
                    </div>
                  </Tab>

                  {/* BRANCHES */}
                  <Tab className="tab-one" eventKey="branches" title="الفروع">
                    <div className="table-content mt-3 table-content-2" data-aos="fade-up">
                      <TableDataInfo
                        titleTableHeader={false}
                        isHashTrue={false}
                        hashId={false}
                        dateThead={
                          <>
                            <th scope="col">اسم الفرع</th>
                            <th scope="col">مدير الفرع</th>
                            <th scope="col">الدولة</th>
                            <th scope="col">المنطقة</th>
                          </>
                        }
                        dataTbody={
                          <>
                            {userInfo?.branches.map((branch) => {
                              return (
                                <tr key={branch.id}>
                                  <td>{branch.name}</td>
                                  <td>{branch.manager_id}</td>
                                  <td>{countries[branch.country_id]?.name_ar}</td>
                                  <td>{regions[branch.region_id]?.name_ar}</td>
                                </tr>
                              );
                            })}
                          </>
                        }
                      />
                    </div>
                  </Tab>

                  {/* RESERVATIONS */}
                  <Tab className="tab-one" eventKey="reservations" title="الحجوزات">
                    <div className="table-content mt-3 table-content-2" data-aos="fade-up">
                      <TableDataInfo
                        titleTableHeader={false}
                        isHashTrue={false}
                        hashId={false}
                        dateThead={
                          <>
                            <th scope="col">اسم العميل</th>
                            <th scope="col">اسم الحلاق</th>
                            <th scope="col">اسم الفرع</th>
                            <th scope="col">موعد الحجز</th>
                            <th scope="col">فاتورة المنصة</th>
                            <th scope="col">فاتورة العميل</th>
                            <th scope="col">قيمة المعاملة</th>
                          </>
                        }
                        dataTbody={
                          <>
                            {userInfo?.bookings?.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.vendor_id}</td>
                                  <td>{item.employee_id}</td>
                                  <td>{item.name}</td>
                                  <td>
                                    {item.booking_day} {item.booking_time}
                                  </td>
                                  <td>
                                    <img
                                      src={imgTicket}
                                      alt="img ticket"
                                      width={"22px"}
                                      height={"22px"}
                                    />
                                    <a
                                      href="##"
                                      className="text-link text-color-num text-decoration-underline ms-1">
                                      فاتورة المنصة{" "}
                                    </a>
                                  </td>
                                  <td>
                                    <img
                                      src={imgTicket}
                                      alt="img ticket"
                                      width={"22px"}
                                      height={"22px"}
                                    />
                                    <a
                                      href="##"
                                      className="text-link text-color-num text-decoration-underline ms-1">
                                      فاتورة العميل{" "}
                                    </a>
                                  </td>
                                  {/* <td>
                                      <p className={item.classStatusBook}>{item.invoice}</p>
                                    </td>
                                    {/* <td>{item.clientInvoice}</td> */}

                                  <td>{item.total}</td>
                                </tr>
                              );
                            })}
                          </>
                        }
                      />
                    </div>
                  </Tab>

                  {/* WALLET */}
                  {/* <Tab className="tab-one" eventKey="wallet" title="المحفظه">
                    <div
                      className="header-top-wallet d-flex align-items-center gap-2 justify-content-between gap-4 mt-3"
                      data-aos="fade-right">
                      <div className="content-wallet-top">
                        <h2 className="title">رصيد المتجر</h2>
                        <h2 className="info-num">{userInfo?.user?.wallet?.balance} ريال</h2>
                      </div>
                      <div className="image-wallet">
                        <img src={imgWallet} alt="img wallet" />
                      </div>
                    </div>
                    <div className="table-content mt-3 table-content-3" data-aos="fade-up">
                      <TableDataInfo
                        titleTableHeader={false}
                        isHashTrue={false}
                        hashId={false}
                        dateThead={
                          <>
                            <th scope="col">وصف المعاملة</th>
                            <th scope="col">قيمة المعاملة</th>
                            <th scope="col">تاريخ المعاملة</th>
                            <th scope="col">نوع المعاملة</th>
                          </>
                        }
                        dataTbody={
                          <>
                            {userInfo?.user?.wallet?.transactions.map((transaction) => {
                              return (
                                <tr key={transaction.id}>
                                  <td>{transaction.description}</td>
                                  <td>
                                    <span className="text-danger">{transaction.value}</span>
                                  </td>
                                  <td>{formatDateToArabic(transaction.created_at)}</td>
                                  <td>{transaction.type}</td>
                                </tr>
                              );
                            })}
                          </>
                        }
                      />
                    </div>
                  </Tab> */}

                  {/* OFFERS */}
                  {/* <Tab className="tab-one" eventKey="offers" title="العروض">
                    <div className="table-content mt-3 table-content-2" data-aos="fade-up">
                      <TableDataInfo
                        titleTableHeader={false}
                        isHashTrue={false}
                        hashId={false}
                        dateThead={
                          <>
                            <th scope="col" className="text-danger">
                              اسم العرض
                            </th>
                            <th scope="col">حالة العرض</th>
                            <th scope="col">تاريخ الانشاء</th>
                            <th scope="col">سعر العرض</th>
                            <th scope="col">الخصم</th>
                          </>
                        }
                        dataTbody={
                          <>
                            {userInfo?.offers.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.name_ar}</td>
                                  <td>
                                    <div
                                      className={
                                        item.is_active === "1" ? "text-success" : "text-danger"
                                      }>
                                      {item.is_active ? "مستمر" : "متوقف"}
                                    </div>
                                  </td>
                                  <td>{formatDateToArabic(item.created_at)}</td>
                                  <td>{item.offer_price} ر.س</td>
                                  <td>{item.discount_percentage} %</td>
                                </tr>
                              );
                            })}
                          </>
                        }
                      />
                    </div>
                  </Tab> */}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsDataUsers;
