import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllPackages } from "store/general/generalActions";
import { getSubscriptions } from "store/vendor/vendorActions";
import { objectToQueryParams } from "../../../utlis";
import "./ProviderSubscription.css";
import SubscriptionCard from "./SubscriptionCard";

const DEFAULT_PARAMS = (vendorId) => ({
  with: ["package"],
  limit: 100,
  offset: 0,
  sort: "ASC",
  paginate: "true",
  field: "id",
  deleted: "all",
  resource: "all",
  resource_columns: ["id", "name_ar"],
  columns: ["vendor_id", "is_active"],
  operand: ["=", "="],
  column_values: [vendorId, "1"],
});

const ProviderSubscription = () => {
  const dispatch = useDispatch();
  const { role, user } = useSelector((state) => state.auth);
  const isAdmin = role === "admin";
  const { model_id: id } = user;

  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [vendorSubscriptions, setVendorSubscriptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllPackages = useCallback(async () => {
    try {
      const res = await dispatch(getAllPackages()).then(unwrapResult);
      setAllPackages(res.data.data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  }, [dispatch]);

  const getVendorSubscription = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = DEFAULT_PARAMS(id);
      const stringParam = objectToQueryParams(params);
      const res = await dispatch(getSubscriptions(stringParam)).then(unwrapResult);
      const allSubscriptionsList = res.data.data.filter((sub) => sub.vendor_id === id);
      setAllSubscriptions(allSubscriptionsList);
      setVendorSubscriptions(allSubscriptionsList);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    fetchAllPackages();
    getVendorSubscription();
  }, [fetchAllPackages, getVendorSubscription]);

  if (isLoading) return <Loader />;

  if (!vendorSubscriptions || vendorSubscriptions.length === 0) {
    return <NoActiveSubscription allPackages={allPackages} />;
  }

  return (
    <>
      <HelmetInfo titlePage={"أشتراك مزود الخدمة"} />
      <PageHeader isAdmin={isAdmin} />
      <SubscriptionContent
        isLoading={isLoading}
        isAdmin={isAdmin}
        vendorSubscriptions={vendorSubscriptions}
        allPackages={allPackages}
        allSubscriptions={allSubscriptions}
      />
    </>
  );
};

const NoActiveSubscription = ({ allPackages }) => (
  <div className="text-center mt-5 fs-5 p-5">
    <p>لا يوجد اشتراك نشط</p>
    <Link
      to="/dashboard/vendor/providersubscription/changepackage"
      state={{ allPackages }}
      className="btn main-btn btn-lg w-50 mx-auto"
      style={{
        borderRadius: "10px",
        backgroundColor: "wheat",
        fontWeight: "bold",
        marginTop: "50px",
      }}
    >
      تصفح الاشتراكات المتاحة
    </Link>
  </div>
);

const PageHeader = ({ isAdmin }) => (
  <div className="d-flex justify-content-between align-items-center flex-wrap">
    <HeaderPageInfo title={"أشتراك مزود الخدمة"} isShowLeftContent={false} contentPageLeft={false} />
    {isAdmin && (
      <div className="link-back">
        <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/admin/serviceprovidersubscriptions">
          عودة
        </Link>
      </div>
    )}
  </div>
);

const SubscriptionContent = ({ isLoading, isAdmin, vendorSubscriptions, allPackages, allSubscriptions }) => (
  <div className="provider-subscription mt-4" data-aos="fade-up">
    {isLoading ? (
      <Loader />
    ) : (
      <SubscriptionCard
        isAdmin={isAdmin}
        subscriptionInfo={null}
        vendorSubscriptions={vendorSubscriptions}
        allPackages={allPackages}
        allSubscriptions={allSubscriptions}
      />
    )}
  </div>
);

export default ProviderSubscription;
