import { useEffect, useState } from "react";
import imgUserEdit from "../../../../assets/images/dashboardImages/imgUser/01.png";
import "./ImageUpload.css";

const ImageUpload = ({ handleImageChange }) => {
  const [image, setImage] = useState(null);

  const handleAddImage = (event) => {
    setImage(event.target.files[0]);
    handleImageChange(event.target.files[0]);
  };

  const [previewUrl, setPreviewUrl] = useState(null);
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreviewUrl(null);
    }
  }, [image]);

  return (
    <>
      <form>
        <div className="image-user-edite mb-4 d-flex justify-content-center align-items-center cursor-pointer-1">
          <label htmlFor="upload-image">
            {previewUrl ? (
              <img src={previewUrl} alt="UploadedImage" width="80px" height="80px" className="object-fit-cover" />
            ) : (
              <img
                src={imgUserEdit}
                alt="img user"
                width="80px"
                height="80px"
                className="object-fit-cover cursor-pointer-1"
              />
            )}
          </label>
          <input id="upload-image" type="file" accept="image/*" onChange={handleAddImage} style={{ display: "none" }} />
        </div>
      </form>
    </>
  );
};

export default ImageUpload;
