import { faEdit, faEllipsisVertical, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";

import { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addSection, getServices, updateService } from "store/vendor/vendorActions";
import { createImageBlob } from "utlis";
import SectionModal from "./SectionModal";
import "./ServicesPage.css";

const ServicesPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [showSectionModal, setShowSectionModal] = useState(false);

  const handleShowAddSectionModal = () => setShowSectionModal(true);
  const handleCloseAddSectionModal = () => setShowSectionModal(false);

  const handleSaveNewSection = async (sectionData) => {
    const { name, description, colors } = sectionData;
    try {
      const imageBlob = await createImageBlob(colors[0]);
      const imageFile = new File([imageBlob], `section-${name}.jpg`, { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("name_ar", name);
      formData.append("name_en", name);
      formData.append("description_ar", description);
      formData.append("description_en", JSON.stringify(colors));
      formData.append("image", imageFile);
      await dispatch(addSection(formData)).then(unwrapResult);
      toast.success("تم إضافة القسم الجديد بنجاح");
    } catch (error) {
      console.error("Error adding new section:", error);
      toast.error("فشل في إضافة القسم الجديد");
    } finally {
      setShowSectionModal(false);
    }
  };

  const [services, setServices] = useState([]);

  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [search, setSearch] = useState("");

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.vendor.isLoading);

  const handleToggleSwitch = (newValue, index) => {
    dispatch(
      updateService({
        ...services[index],
        is_active: `${newValue ? "1" : "0"}`,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        const updatedData = [...services];
        updatedData[index].is_active = newValue ? "1" : "0";
        toast.success("تم التعديل بنجاح.");
        setServices(updatedData);
      })
      .catch((error) => {
        console.log(error, "error");
        toast.error("فشلت العملية");
      });
  };

  const fetchServices = useCallback(
    (params) => {
      dispatch(getServices(params))
        .then(unwrapResult)
        .then((res) => {
          setServices(res.data.data);
          console.log("res.data", res.data.data);
          setCurrentPageItemCounts(res.data.data?.length || 0);
          setTotalItemsCount(res.data.total || 0);
          setTotalPagesCount(res.data.links.length - 2 || 0);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const searchParams = {
      with: [
        "category",
        "vendor",
        "createdBy",
        "branches",
        "section",
        "employees",
        "employees.user",
      ],
      limit: limit,
      offset: currentPage,
      sort: "DESC",
      paginate: "true",
      field: "id",
      deleted: "all", //all,deleted
      resource: "all", //all,custom
      resource_columns: ["id", "name_ar"],
      columns: ["name_ar", "vendor_id"],
      operand: ["like", "="],
      column_values: [`%${search}%`, user.model_id],
    };
    fetchServices(searchParams);
  }, [limit, currentPage, search]);

  return (
    <>
      <HelmetInfo titlePage={"الخدمات"} />
      <SectionModal
        showModalAddTab={showSectionModal}
        hideModalAddTab={handleCloseAddSectionModal}
        onClose={handleCloseAddSectionModal}
        onSave={handleSaveNewSection}
      />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={" الخدمات"}
          isShowLeftContent={true}
          contentPageLeft={
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <SearchHeaderPage
                typeIcon={false}
                textButton={false}
                functionButton={false}
                isButtonActive={false}
                onSearchChange={setSearch}
              />
              <Dropdown>
                <Dropdown.Toggle className="main-btn mx-2">اضافة جديد</Dropdown.Toggle>

                <Dropdown.Menu className="drop-menu-head">
                  <Dropdown.Item
                    onClick={() => {
                      navigate("editaddServicesDetails");
                    }}>
                    خدمة جديدة
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => handleShowAddSectionModal()}>
                    تبويب جديد
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
        />
      </div>
      <div className="table-content mt-3 table-content-2">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">اسم الخدمة</th>
                <th scope="col">حالة الخدمة</th>
                <th scope="col">القسم</th>
                <th scope="col">السعر</th>
                <th scope="col">خدمة مميزة</th>
                <th scope="col">تفعيل/الغاء</th>
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {services.length > 0 &&
                  services?.map((item, index) => {
                    return (
                      <tr key={`${item.id}-${index}`}>
                        <td>{item?.name_ar}</td>
                        <td>
                          <p className={item?.is_active ? "text-success" : "text-danger"}>
                            {item?.is_active ? "نشط" : "غير نشط"}
                          </p>
                        </td>
                        <td>{item.category.name_ar}</td>
                        <td>{item.price}</td>
                        <td>{item.featured ? "نعم" : "لا"}</td>

                        {item.isEmpty === true ? (
                          ""
                        ) : (
                          <td>
                            <Toggleswitch
                              switchId={item.id}
                              nameSwitch={`services-${item.id}`}
                              value={item.is_active === "1" ? true : false}
                              onChange={(newValue) => {
                                handleToggleSwitch(newValue, index);
                              }}
                            />
                          </td>
                        )}

                        {item.isEmpty === true ? (
                          ""
                        ) : (
                          <td>
                            <IconsTable
                              typeIcon1={faEye}
                              functionEye={() => {
                                navigate(`servicesDetails/${item.id}`);
                              }}
                              typeIcon2={faEdit}
                              functionEdit={() => {
                                navigate(`editaddServicesDetails/${item.id}`);
                              }}
                              isDeleteIcon={false}
                              functionDelete={false}
                              typeIcon3={false}
                            />
                          </td>
                        )}

                        {item.isEmpty === true ? (
                          <>
                            <td></td>

                            <td>
                              <Dropdown className="menu-drop-1">
                                <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                  <FontAwesomeIcon icon={faEllipsisVertical} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href="#/action-1"
                                    onClick={() => {
                                      navigate(`editaddServicesDetails/${item.id}`);
                                    }}>
                                    تعديل
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-2">حذف</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td></td>
                          </>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    );
                  })}
              </>
            }
          />
        )}
      </div>

      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
        isShowTotalPagesCount={true}
      />
    </>
  );
};

export default ServicesPage;
