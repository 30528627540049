import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSubscriptions } from "store/admin/adminActions";
import TableDataInfo from "../../Components/UiDashboard/TablesData/TableDataInfo";
import "./ServicesDetails.css";

const ServiceProviderSubscriptions = () => {
  const [tableData1, setTableData1] = useState([]);

  const [subscriptions, setSubscriptions] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.admin.isLoading);

  const fetchSubscriptions = (params) => {
    dispatch(getSubscriptions(params))
      .then(unwrapResult)
      .then((res) => {
        setSubscriptions(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchSubscriptions({ per_page: limit, page: currentPage });
  }, [limit, currentPage]);

  return (
    <>
      <HelmetInfo titlePage={"اشتراكات مزودي الخدمة"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"اشتراكات مزودي الخدمة"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              typeIcon={false}
              textButton={false}
              functionButton={false}
              isButtonActive={false}
            />
          }
        />
      </div>
      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">اسم مزود الخدمه</th>
                <th scope="col">اسم الباقه </th>
                <th scope="col">نوع الاشتراك</th>
                {/* <th scope="col">الاجراءات</th> */}
              </>
            }
            dataTbody={
              <>
                {subscriptions?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item?.vendor?.name}</td>
                      <td>{item?.package?.name_ar}</td>
                      <td>{item?.package?.subscription_type}</td>
                      {/* <td>
                        <IconsTable
                          typeIcon1={faEye}
                          functionEye={() => {
                            navigate(`details/${item.id}`);
                          }}
                          isDeleteIcon={false}
                        />
                      </td> */}
                    </tr>
                  );
                })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default ServiceProviderSubscriptions;
