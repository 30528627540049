import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBookings, getServicesReport } from "../../../store/vendor/vendorActions";
import "./ReportDetails.css";

const ServicesReport = () => {
  const dispatch = useDispatch();
  const user = useGetAuthUser();
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [totalServicesCount, setTotalServicesCount] = useState(1);
  const [allBookings, setAllBookings] = useState([]);
  const [topServices, setTopServices] = useState([]);

  useEffect(() => {
    const serviceMap = new Map(services.map((service) => [service.id, service.name_ar]));

    const serviceCounts = new Map();
    allBookings.forEach((booking) => {
      booking.services.forEach((svc) => {
        const count = (serviceCounts.get(svc.id) || 0) + 1;
        serviceCounts.set(svc.id, count);
      });
    });

    const sortedServiceCounts = Array.from(serviceCounts.entries())
      .sort((a, b) => b[1] - a[1])
      .map(([id, count]) => ({ name: serviceMap.get(id), count }));

    setTopServices(sortedServiceCounts);
  }, [allBookings, services]);

  const fetchServicesReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["booking"],
      per_page: 100,
      limit: 100,
      sort: "DESC",
      paginate: "true",
    };
    dispatch(getServicesReport(params))
      .then(unwrapResult)
      .then((res) => {
        setServices(res.data.data);
        setTotalServicesCount(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const fetchVendorBookings = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["services"],
      limit: 200,
      per_page: 200,
      sort: "DESC",
      paginate: "true",
      columns: ["vendor_id"],
      operand: ["="],
      column_values: [user?.model_id],
    };
    dispatch(getBookings(params))
      .then(unwrapResult)
      .then((res) => {
        setAllBookings(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, user?.model_id]);

  useEffect(() => {
    fetchServicesReport();
    fetchVendorBookings();
  }, [fetchServicesReport, fetchVendorBookings]);

  const displayData = {
    totalServices: totalServicesCount,
    mostRequestedServices: topServices,
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      <>
        <div className="report-summary">
          <div className="summary-card card-shadow card-hover">
            <div className="card-content">
              <h3>{displayData.totalServices}</h3>
              <p>عدد الخدمات</p>
            </div>
            <FontAwesomeIcon icon={faList} className="icon" />
          </div>
        </div>
      </>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">الخدمات الأكثر طلبا</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الخدمة</th>
              <th>عدد الحجوزات</th>
            </tr>
          </thead>
          <tbody>
            {displayData.mostRequestedServices.map((service, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{service.name}</td>
                <td>{service.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServicesReport;
