import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconsTable.css";
const IconsTable = ({
  typeIcon1,
  functionEye,
  typeIcon2,
  functionEdit,
  isDeleteIcon,
  functionDelete,
  typeIcon3,
}) => {
  return (
    <>
      <div className="all-icon-info d-flex align-items-center  gap-4">
        <button className="link-icon bg-transparent border-0 " onClick={functionEye}>
          <FontAwesomeIcon icon={typeIcon1} />
        </button>
        {typeIcon2 && (
          <button className="link-icon bg-transparent  border-0" onClick={functionEdit}>
            <FontAwesomeIcon icon={typeIcon2} />
          </button>
        )}
        {isDeleteIcon && (
          <button className="link-icon bg-transparent  border-0" onClick={functionDelete}>
            <FontAwesomeIcon icon={typeIcon3} />
          </button>
        )}
      </div>
    </>
  );
};

export default IconsTable;
