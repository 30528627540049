import { unwrapResult } from "@reduxjs/toolkit";
import { baseURL } from "Apis/axiosService";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getService } from "store/admin/adminActions";
import { getService as getVendorService } from "store/vendor/vendorActions";

const ServicesDetailsInfo = () => {
  const dispatch = useDispatch();
  const [serviceInfo, setServiceInfo] = useState(null);
  const isLoading = useSelector((state) => state.admin.isLoading);
  const isVendorLoading = useSelector((state) => state.vendor.isLoading);
  const { idServicesDetails, id } = useParams();

  const fetchService = () => {
    dispatch(getService(idServicesDetails))
      .then(unwrapResult)
      .then((res) => {
        setServiceInfo(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchVendorService = () => {
    dispatch(getVendorService(id))
      .then(unwrapResult)
      .then((res) => {
        setServiceInfo(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (idServicesDetails) {
      fetchService();
    }
    if (id) {
      fetchVendorService();
    }
  }, []);

  const titles = [
    { id: 0, title: "اسم الخدمة", getValue: () => serviceInfo?.name_ar },
    { id: 1, title: "سعر الخدمة", getValue: () => serviceInfo?.price },
    {
      id: 2,
      title: "حالة الخدمة",
      getClassText: () => (serviceInfo?.is_active ? "text-success" : "text-danger"),
      getValue: () => (serviceInfo?.is_active === "1" ? "نشط" : "غير نشط"),
    },
    {
      id: 3,
      title: "تبويب الخدمة",
      getValue: () => serviceInfo?.section?.name_ar || serviceInfo?.section_id,
    },
    {
      id: 4,
      title: "الوقت المستغرق لاتمام الخدمة",
      getValue: () => serviceInfo?.service_time,
    },
    {
      id: 5,
      title: "وصف الخدمة",
      getValue: () => serviceInfo?.description_ar,
    },
    {
      id: 6,
      title: "القسم",
      getValue: () => serviceInfo?.category?.name_ar || serviceInfo?.category_id,
    },
    {
      id: 7,
      title: "الفروع للخدمة",
      getValue: () => serviceInfo?.branches?.map((branch) => branch.name).join(","),
    },
    {
      id: 8,
      title: "الخدمة مميزة ؟",
      text: "نعم",
      getValue: () => (serviceInfo?.featured ? "نعم" : "لا"),
    },
    {
      id: 9,
      title: "الوقت الاضافى لاعداد الخدمة",
      getValue: () => serviceInfo?.extra_time,
    },
    {
      id: 10,
      title: "الموظفون للخدمة",
      getValue: () =>
        serviceInfo?.employees?.map((employee) => (
          <li key={`employee-${employee.id}`} className="d-flex  align-items-center  gap-3">
            <img src={`${baseURL}/${employee.image}`} alt={employee.name} width={"35px"} height={"35px"} />
            <span>{employee.name}</span>
          </li>
        )),
    },
  ];

  return (
    <>
      <HelmetInfo titlePage={"تفاصيل الخدمة"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/admin/homedashboard"}
          titleInfoPage={"تفاصيل الخدمة"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to={id ? "/dashboard/vendor/servicespage" : "/dashboard/admin/servicespage"}
          >
            عودة
          </Link>
        </div>
      </div>
      <div className="basic-information-content account-info-details mt-3" data-aos="fade-up">
        {/* =========== START ALL BASIC INFO ============= */}
        <div className="all-basic-info all-details-branch">
          {/* ======== START HEADER INFO ========== */}
          <div className="header-info-profile ">
            {isLoading || isVendorLoading ? (
              <Loader />
            ) : (
              <div className="content-user-info">
                {titles.map((item) => {
                  return (
                    <div className="all-info-content  d-flex" key={`info-${item.id}`}>
                      {/* ========= START MAIN INFO ONE ========= */}
                      <div className="main-info-one d-flex  align-items-center">
                        <h2 className="title">{item.title}</h2>
                      </div>
                      {/* ========= END MAIN INFO ONE ========= */}
                      {/* ========= START MAIN INFO ONE ========= */}
                      <div className="main-info-one d-flex  align-items-center">
                        <p className={`text  ${item.getClassText ? item.getClassText() : ""}`}>
                          {item.id !== 10 ? item.getValue() : ""}
                        </p>
                        {item.id === 10 && (
                          <ul className="m-0 p-0 d-flex  align-items-center flex-wrap   gap-3">{item.getValue()}</ul>
                        )}
                      </div>
                      {/* ========= END MAIN INFO ONE ========= */}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {/* ======== END HEADER INFO ========== */}
        </div>
        {/* =========== END ALL BASIC INFO ============= */}
      </div>
    </>
  );
};

export default ServicesDetailsInfo;
