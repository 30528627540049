import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { registerServiceProvider } from "store/vendor/vendorActions";
import * as Yup from "yup";
// Import the registration action (you'll need to create this)

const validationSchema = Yup.object({
  email: Yup.string().email("ايميل خطاء").required("البريدالالكترونى مطلوب."),
  first_name: Yup.string().required("الاسم الاول مطلوب"),
  last_name: Yup.string().required("الاسم الاخير مطلوب"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "رقم الجوال غير صحيح")
    .required("رقم الهاتف مطلوب"),
  password: Yup.string().required("كلمة السر مطلوبة").min(8, "يجب ان تكون كلمة السر اكثر من 8 احرف وارقام"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "كلمة السر غير متطابقة")
    .required("تأكيد كلمة السر مطلوب"),
});

const RegisterServicesStepThree = ({ registerData, setRegisterData, handlePrev }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    email: registerData.email || "",
    first_name: registerData.first_name || "",
    last_name: registerData.last_name || "",
    phone: registerData.phone || "",
    password: "",
    password_confirmation: "",
  };

  const handleSubmit = async (values) => {
    const finalData = {
      ...registerData,
      ...values,
    };

    try {
      const response = await dispatch(registerServiceProvider(finalData)).unwrap();
      if (response.success) {
        toast.success("تم تسجيل مزود الخدمة بنجاح");
        navigate("/dashboard/homedashboard");
      }
    } catch (error) {
      console.log({ error });
      const errors = Object.values(error.errors).map((error) => error[0]);
      errors.forEach((error) => toast.error(error));
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "password_confirmation") {
      setShowRePassword(!showRePassword);
    }
  };

  const isDisabled = () => {
    return (
      !registerData.email ||
      !registerData.first_name ||
      !registerData.last_name ||
      !registerData.phone ||
      !registerData.password ||
      !registerData.password_confirmation
    );
  };

  return (
    <div className="all-step-three bg-step">
      <FormField initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <div className="row g-2">
          <div className="col-12 col-md-6">
            <InputFiled
              label={"الاسم الأول"}
              name="first_name"
              type="text"
              placeholder={"الاسم الأول"}
              success
              handleOnChange={(value) => {
                setRegisterData((prevData) => ({ ...prevData, first_name: value }));
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <InputFiled
              label={"الاسم الأخير"}
              name="last_name"
              type="text"
              placeholder={"الاسم الأخير"}
              success
              handleOnChange={(value) => {
                setRegisterData((prevData) => ({ ...prevData, last_name: value }));
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <InputFiled
              label={"الرقم الجوال"}
              name="phone"
              type="tel"
              placeholder={"الرقم الجوال"}
              success
              handleOnChange={(value) => {
                setRegisterData((prevData) => ({ ...prevData, phone: value }));
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <InputFiled
              label={"البريد الألكتروني"}
              name="email"
              type="email"
              placeholder={"البريد الألكتروني"}
              success
              handleOnChange={(value) => {
                setRegisterData((prevData) => ({ ...prevData, email: value }));
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="input-pass position-relative">
              <InputFiled
                label="كلمة المرور"
                name="password"
                placeholder="كلمة المرور"
                success
                type={showPassword ? "text" : "password"}
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, password: value }));
                }}
              />
              <button type="button" className="icon-eye-button" onClick={() => togglePasswordVisibility("password")}>
                {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
              </button>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-pass position-relative">
              <InputFiled
                label="تاكيد كلمة المرور "
                name="password_confirmation"
                placeholder="تأكيد كلمة المرور"
                success
                type={showRePassword ? "text" : "password"}
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, password_confirmation: value }));
                }}
              />
              <button
                type="button"
                className="icon-eye-button"
                onClick={() => togglePasswordVisibility("password_confirmation")}>
                {showRePassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
              </button>
            </div>
          </div>
        </div>
        <div className="button-container mt-4">
          <button type="button" className="btn main-btn btn-prev" onClick={handlePrev}>
            السابق
          </button>
          <button type="submit" className="btn main-btn btn-submit" disabled={isDisabled()}>
            تسجيل
          </button>
        </div>
      </FormField>
    </div>
  );
};

export default RegisterServicesStepThree;
