import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCanncelationReasons } from "store/general/generalActions";
import ModalAddNewReason from "./Modals/ModalAddNewReason";
import ModalEditReason from "./Modals/ModalEditReason";

const ReasonsCancelingReservation = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.general.isLoading);

  const [reasons, setReasons] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [showModalEditReason, setModalEditReason] = useState(false);
  const [showModalAddReason, setModalAddReason] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(null);

  // ADD MODAL
  const showMoalAddReasonFunc = () => {
    setModalAddReason(true);
  };

  const hideModalAddReason = () => {
    setModalAddReason(false);
  };

  // EDIT MODAL
  const handleEdit = (id) => {
    setCurrentEdit(id);
    setModalEditReason(true);
  };

  const hideModalEditReason = () => {
    setModalEditReason(false);
    setCurrentEdit(null);
  };

  const fetchReasons = (params) => {
    dispatch(getCanncelationReasons(params))
      .then(unwrapResult)
      .then((res) => {
        setReasons(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchReasons({ per_page: limit, page: currentPage });
  }, [limit, currentPage]);

  return (
    <>
      <HelmetInfo titlePage={"أسباب الغاء الحجز"} />
      <ModalAddNewReason
        showModalAddReason={showModalAddReason}
        hideModalAddReason={hideModalAddReason}
        fetchData={fetchReasons}
      />
      <ModalEditReason
        showModalEditReason={showModalEditReason}
        hideModalEditReason={hideModalEditReason}
        id={currentEdit}
        data={reasons.find((item) => item.id === currentEdit)}
        fetchData={fetchReasons}
      />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"أسباب الغاء الحجز"}
          isShowLeftContent={true}
          contentPageLeft={
            <>
              <button className="main-btn" onClick={showMoalAddReasonFunc}>
                <FontAwesomeIcon icon={faPlus} />
                اضافة سبب جديد
              </button>
            </>
          }
        />
      </div>

      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">السبب</th>

                <th scope="col">تفعيل/الغاء</th>
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {reasons?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.reason_ar}</td>

                      <td>
                        <Toggleswitch
                          value={!(item.is_active === "1" ? true : false)}
                          switchId={item.id}
                          nameSwitch={"switch01"}
                        />
                      </td>
                      <td>
                        <div
                          onClick={() => handleEdit(item.id)}
                          className="icon-eye-offer text-color-num cursor-pointer-1"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default ReasonsCancelingReservation;
