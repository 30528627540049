import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import searchIcon from "../../../assets/images/icons/search.svg";
import imgUser from "../../../assets/images/userImg/user.png";
import { getBookings } from "../../../store/vendor/vendorActions";
import "./Calendar.css";
const defaultImage = imgUser;

const BookingCell = ({ booking }) => {
  const { booking_time, employee, booking_service } = booking;
  const service = booking_service[0].service;

  const getBookingColor = (serviceName) => {
    const colors = ["#FFB3BA", "#BAFFC9", "#BAE1FF", "#FFFFBA"];
    return colors[serviceName.length % colors.length];
  };

  return (
    <div
      className="booking-cell"
      style={{
        backgroundColor: getBookingColor(service.name_ar),
        padding: "10px",
        borderRadius: "5px",
        marginBottom: "5px",
      }}
    >
      <div style={{ fontSize: "12px", marginBottom: "5px" }}>{booking_time}</div>
      <div style={{ fontWeight: "bold", marginBottom: "5px" }}>{employee.user.name}</div>
      <div style={{ fontSize: "12px" }}>{service.name_ar}</div>
    </div>
  );
};

const Calendar = () => {
  const user = useSelector((state) => state.auth.user);
  const [bookings, setBookings] = useState([]);
  const [branches, setBranches] = useState([]);
  const [employees, setEmployees] = useState([]);
  const services = useSelector((state) => state.vendor.services);
  const [startDate, setStartDate] = useState(new Date());
  const [searchInput, setSearchInput] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedService, setSelectedService] = useState("");

  const dispatch = useDispatch();

  const updateBranches = useMemo(() => {
    return (bookingsData) => {
      const uniqueBranches = bookingsData.reduce((acc, booking) => {
        if (!acc.some((branch) => branch.id === booking.branch.id)) {
          acc.push(booking.branch);
        }
        return acc;
      }, []);
      setBranches(uniqueBranches.map(({ id, name }) => ({ id, name })));
    };
  }, []);

  const updateEmployees = useMemo(() => {
    return (bookingsData) => {
      const uniqueEmployees = bookingsData.reduce((acc, booking) => {
        if (!acc.some((employee) => employee.id === booking.employee.id)) {
          acc.push(booking.employee);
        }
        return acc;
      }, []);
      setEmployees(uniqueEmployees.map(({ id, name, user }) => ({ id, name, user })));
    };
  }, []);

  const fetchBookings = useCallback(async () => {
    const weekStart = new Date(startDate);
    weekStart.setDate(startDate.getDate() - startDate.getDay());
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6);

    const params = {
      with: ["branch", "bookingService.service", "employee", "employee.user"],
      limit: 100,
      offset: 0,
      sort: "ASC",
      paginate: "true",
      field: "id",
      deleted: "all",
      resource: "all",
      resource_columns: ["id", "name"],
      columns: ["vendor_id", "booking_day"],
      operand: ["=", "="],
      column_values: [user?.model_id, weekStart.toISOString().split("T")[0]],
    };

    if (selectedBranch) {
      params.columns.push("branch_id");
      params.operand.push("=");
      params.column_values.push(selectedBranch);
    }

    if (selectedEmployee) {
      params.columns.push("employee_id");
      params.operand.push("=");
      params.column_values.push(selectedEmployee);
    }

    if (selectedService) {
      params.columns.push("service_id");
      params.operand.push("=");
      params.column_values.push(selectedService);
    }

    try {
      const { data } = await dispatch(getBookings(params)).unwrap();
      console.log("data", data.data);
      setBookings(data.data);
      updateBranches(data.data);
      updateEmployees(data.data);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  }, [
    startDate,
    selectedBranch,
    selectedEmployee,
    selectedService,
    dispatch,
    user?.model_id,
    updateBranches,
    updateEmployees,
  ]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    console.log("handle search", searchInput);
    fetchBookings();
  };

  const handleSearchInputChange = (event) => {
    console.log("handle search input change", event.target.value);
    setSearchInput(event.target.value);
  };

  const handleBranchChange = (event) => {
    console.log("handle branch change", event.target.value);
    setSelectedBranch(event.target.value);
  };

  const handleEmployeeChange = (event) => {
    console.log("handle employee change", event.target.value);
    setSelectedEmployee(event.target.value);
  };

  const handleServiceChange = (event) => {
    console.log("handle service change", event.target.value);
    setSelectedService(event.target.value);
  };

  const timeSlots = Array.from({ length: 16 }, (_, i) => `${i + 9}:00`);

  return (
    <div className="calendar-container">
      <HelmetInfo titlePage={"التقويم"} />
      <HeaderPageInfo title={"التقويم"} isShowLeftContent={false} contentPageLeft={false} />

      <div className="header-search-calender pt-3 pb-4" data-aos="fade-left">
        <div className="row g-3">
          <div className="col-12 col-md-2">
            <div className="input-date-info">
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                className="form-control"
                placeholderText="التاريخ"
              />
              <div className="input-icon">
                <FontAwesomeIcon icon={faCalendar} />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="select-input">
              <select className="form-select" value={selectedBranch} onChange={handleBranchChange}>
                <option value="">الفرع</option>
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="select-input">
              <select className="form-select" value={selectedEmployee} onChange={handleEmployeeChange}>
                <option value="">الموظف</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.user.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="select-input">
              <select className="form-select" value={selectedService} onChange={handleServiceChange}>
                <option value="">الخدمة</option>
                {services.map((service) => (
                  <option key={service.id} value={service.id}>
                    {service.name_ar}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="left-info-search">
              <form onSubmit={handleSearch}>
                <div className="form-search position-relative ">
                  <input
                    type="text"
                    className="form-control"
                    id="searchInput"
                    placeholder="ابحث باسم الموظف"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                  />
                  <div
                    className="icon-search position-absolute top-50 end-0 translate-middle cursor-pointer-1"
                    onClick={handleSearch}
                  >
                    <img src={searchIcon} alt="icon search" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="calender">
        <div className="table-data-content">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  {employees.map((employee) => (
                    <th key={employee.id}>
                      <div
                        className="employee-header"
                        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                      >
                        <img
                          src={employee.user.image ?? defaultImage}
                          className="img-fluid"
                          alt={" "}
                          width={"50px"}
                          height={"50px"}
                          style={{ borderRadius: "50%", marginBottom: "10px" }}
                        />
                        <span>{employee.user.name}</span>{" "}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {timeSlots.map((timeSlot, rowIndex) => (
                  <tr key={rowIndex}>
                    <th>{timeSlot}</th>
                    {employees.map((employee, colIndex) => {
                      const cellBookings = bookings.filter(
                        (booking) =>
                          booking.employee.id === employee.id &&
                          booking.booking_time.split(":")[0] === timeSlot.split(":")[0]
                      );
                      return (
                        <td key={colIndex}>
                          {cellBookings.map((booking, index) => (
                            <BookingCell key={index} booking={booking} />
                          ))}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
