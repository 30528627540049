import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AdminReduder from "./admin/adminSlice";
import AuthReducer from "./auth/authSlice";
import customerSlice from "./customer/customerSlice";
import GeneralReducer from "./general/generalSlice";
import vendorSlice from "./vendor/vendorSlice";

const Reducer = combineReducers({
  auth: AuthReducer,
  admin: AdminReduder,
  general: GeneralReducer,
  vendor: vendorSlice,
  customer: customerSlice,
});

const STORE = configureStore({
  reducer: Reducer,
});

// export type RootState = ReturnType<typeof STORE.getState>;
// export type AppDispatch = typeof STORE.dispatch;

export default STORE;
