import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getHelpCenter } from "store/admin/adminActions";
import "./HelpCenter.css";
const HelpCenter = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.admin.isLoading);
  const [helpCenterData, setHelpCenterData] = useState([]);

  const fetchData = () => {
    dispatch(getHelpCenter())
      .then(unwrapResult)
      .then((res) => {
        setHelpCenterData(res.data.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <HelmetInfo titlePage={"مركز المساعدة"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"مركز المساعدة"}
          isShowLeftContent={false}
          contentPageLeft={
            <SearchHeaderPage
              typeIcon={false}
              textButton=""
              functionButton={false}
              isButtonActive={true}
              onSearchChange={false}
            />
          }
        />
      </div>
      <div className="help-center mt-3" data-aos="fade-up">
        {/* ========== START ALL HELP CENTER ========== */}
        <div className="all-help-center">
          {/* ========== START ROW ======== */}
          <div className="row g-3">
            {isLoading ? (
              <Loader />
            ) : (
              helpCenterData?.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
                    <Link to={`${item.videoPath}`}>
                      {/* ======== START HELP CARD ONE ========= */}
                      <div className="help-card-one">
                        <div className="img-card">
                          <img src={item.photo} alt="img card" className="w-100 h-100 object-fit-cover" />
                        </div>
                        <div className="text-title">
                          <h2 className="title">{item.title}</h2>
                        </div>
                      </div>
                      {/* ======== END HELP CARD ONE ========= */}
                    </Link>
                  </div>
                );
              })
            )}
          </div>
          {/* ========== END ROW ======== */}
        </div>
        {/* ========== END ALL HELP CENTER ========== */}
      </div>
    </>
  );
};

export default HelpCenter;
