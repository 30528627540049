import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getVendorSettings, updateVendorSettings } from "store/vendor/vendorActions";
import { setVendorSettings } from "store/vendor/vendorSlice";
import ModalBookingDifference from "./Modals/ModalBookingDifference";
import ModalCancellationReservation from "./Modals/ModalCancellationReservation";
import ModalRescheduleReservation from "./Modals/ModalRescheduleReservation";
import ModalReservationExtent from "./Modals/ModalReservationExtent";
import SettingCard from "./SettingCard";
import "./Settings.css";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vendorSettings = useSelector((state) => state.vendor.vendorSettings);
  const setNewSettings = async (settings) => {
    try {
      const response = await dispatch(updateVendorSettings(settings)).unwrap();
      console.log(response);
      if (response.success === true) {
        toast.success("تم تحديث الإعدادات بنجاح");
      }
    } catch (error) {
      console.error("Failed to update vendor settings:", error);
    }
  };

  useEffect(() => {
    if (Object.keys(vendorSettings).length === 0) {
      dispatch(getVendorSettings())
        .unwrap()
        .then((result) => {
          const settings = {
            stock_alert: result.data.stock_alert,
            calender_differance: result.data.calender_differance,
            calender_differance_type: result.data.calender_differance_type,
            booking_differance: result.data.booking_differance,
            booking_range: result.data.booking_range,
            cancel_booking: result.data.cancel_booking,
            reschedule_booking: result.data.reschedule_booking,
            accept_payment: result.data.accept_payment,
          };
          dispatch(setVendorSettings(settings));
        })
        .catch((error) => {
          console.error("Failed to fetch vendor settings:", error);
        });
    }
  }, []);

  const [modals, setModals] = useState({
    booking: false,
    reservation: false,
    cancel: false,
    reschedule: false,
  });

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
  };

  const settingsItems = [
    {
      title: "تحديث فارق الحجز للخدمة",
      text: "تعديل فارق الوقت بين الحجوزات",
      path: "updateBookingDifference",
      modalName: "booking",
      enable: true,
    },
    {
      title: "إلغاء الحجز",
      text: "إدارة عملية إلغاء الحجوزات",
      path: "cancelBooking",
      modalName: "cancel",
      enable: true,
    },
    {
      title: "اعادة جدولة الحجز",
      text: "تنظيم عملية إعادة جدولة الحجوزات",
      path: "rescheduleBooking",
      modalName: "reschedule",
      enable: true,
    },
    {
      title: "اسباب إلغاء الحجز",
      text: "إدارة أسباب إلغاء الحجوزات",
      path: "clientReasonsCancelReservation",
      modalName: "cancel",
      enable: true,
    },
    {
      title: "طريقة الدفع",
      text: "إدارة طرق الدفع المتاحة",
      path: "settingsPay",
      modalName: null,
      enable: true,
    },
    {
      title: "شروط مزود الخدمة",
      text: "تحديث شروط وأحكام مزود الخدمة",
      path: "serviceProviderTermsPage",
      modalName: null,
      enable: true,
    },
    // {
    //   title: "استلام الإشعارات",
    //   text: "تخصيص إعدادات استلام الإشعارات",
    //   path: "receiveNotifications",
    //   modalName: null,
    //   enable: false,
    // },
    // {
    //   title: "إعدادات إرسال الإشعارات التذكيرية للعميل",
    //   text: "ضبط جدولة وتكرار الإشعارات التذكيرية",
    //   path: "customerReminderSettings",
    //   modalName: null,
    //   enable: false,
    // },
    // {
    //   title: "بيانات الشركة والنشاط",
    //   text: "تحديث معلومات الشركة وتفاصيل النشاط",
    //   path: "companyInfo",
    //   modalName: null,
    //   enable: false,
    // },
  ];

  const renderSettingsCards = (items) => {
    return items.map((item, index) => (
      <SettingCard
        key={index}
        title={item.title}
        text={item.text}
        iconArrow={faChevronLeft}
        functionCard={() => {
          if (item.enable) {
            if (item.modalName) {
              toggleModal(item.modalName);
            } else {
              navigate(item.path);
            }
          } else {
            console.log("not enable");
          }
        }}
        newClass="card-setting-content"
        enable={item.enable}
      />
    ));
  };

  return (
    <>
      <HelmetInfo titlePage="الإعدادات" />
      <ModalBookingDifference
        showModalBooking={modals.booking}
        hideModalBooking={() => toggleModal("booking")}
        setSettings={(values) => setNewSettings(values)}
      />
      <ModalReservationExtent
        showModalReservation={modals.reservation}
        hideModalReservation={() => toggleModal("reservation")}
        setSettings={(values) => setNewSettings(values)}
      />
      <ModalCancellationReservation
        showModalCancel={modals.cancel}
        hideModalCancel={() => toggleModal("cancel")}
        setSettings={(values) => setNewSettings(values)}
      />
      <ModalRescheduleReservation
        showModalResch={modals.reschedule}
        hideModalResch={() => toggleModal("reschedule")}
        setSettings={(values) => setNewSettings(values)}
      />

      <div className="header-page-w" data-aos="fade-left">
        <HeaderPageInfo
          title="الإعدادات"
          isShowLeftContent={true}
          contentPageLeft={
            <div className="link-back">
              <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/admin/homedashboard">
                عودة
              </Link>
            </div>
          }
        />
      </div>

      <div className="all-cards-settings my-3" data-aos="fade-up">
        <div className="row g-3">
          <div className="col-md-6">
            <div className="main-cards-settings border p-2 rounded-3">
              {renderSettingsCards(settingsItems.slice(0, Math.ceil(settingsItems.length / 2)))}
            </div>
          </div>
          <div className="col-md-6">
            <div className="main-cards-settings border p-2 rounded-3">
              {renderSettingsCards(settingsItems.slice(Math.ceil(settingsItems.length / 2)))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
