import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import DatePickerField from "Components/Forms/DatePickerField";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addPromo } from "store/general/generalActions";
import * as Yup from "yup";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";

const validationSchema = Yup.object().shape({
  value: Yup.string().required(" مطلوب"),
  discount_type: Yup.string().required(" مطلوب"),
  is_active: Yup.string().required(" مطلوب"),
  start_date: Yup.string().required(" مطلوب"),
  end_date: Yup.string().required(" مطلوب"),
  uses: Yup.string().required(" مطلوب"),
  category_type: Yup.string().required(" مطلوب"),
});

const EditAddDiscountCodes = () => {
  const navigate = useNavigate();
  const initialValues = {
    value: "",
    discount_type: "",
    is_active: "",
    start_date: "",
    end_date: "",
    uses: "",
    category_type: "",
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    const updatedValues = {
      ...values,
      user_id: user.model_id,
    };
    dispatch(addPromo(updatedValues))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم الاضافة بنجاح.", { autoClose: 1000 });
        resetForm();
        navigate("/dashboard/vendor/discountCodes");
      })
      .catch((error) => {
        setErrors(error.errors);
        console.log(error, "error");
      });
  };

  return (
    <>
      <HelmetInfo titlePage={"اضافة تعديل اكواد الخصم"} />
      <div
        className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3"
        data-aos="fade-left"
      >
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/vendor/homedashboard"}
          titleInfoPage={"اضافة / تعديل اكواد الخصم"}
        />
        <div className="link-back">
          <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/vendor/discountCodes">
            عودة
          </Link>
        </div>
      </div>

      <div className="form-discount" data-aos="fade-up">
        <FormField initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <div className="row g-1 g-sm-3">
            <div className="col-12 col-md-6">
              <div className="select-reg m-0">
                <SelectField
                  name="discount_type"
                  label="نوع الخصم"
                  options={[
                    { value: "fixed", label: "قيمة" },
                    { value: "percentage", label: "نسبة" },
                  ]}
                  valueSelected={"نوع الخصم"}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <InputFiled label={"قيمة الخصم"} name="value" type="number" placeholder={"قيمة الخصم"} success />
            </div>
            <div className="col-12 col-md-6">
              <div className="date-birth">
                <label htmlFor="exampleFormControl01" className="form-label">
                  صلاحية الكود من
                </label>
                <div className="input-date-info">
                  <DatePickerField
                    // selected={codeValidity}
                    // onChange={handleDateChange1}
                    // className="form-control"
                    placeholder="صلاحية الكود من"
                    name="start_date"
                  />
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="date-birth">
                <label htmlFor="exampleFormControl02" className="form-label">
                  صلاحية الكود الى
                </label>
                <div className="input-date-info">
                  <DatePickerField
                    // selected={codeValidity2}
                    // onChange={handleDateChange2}
                    // className="form-control"
                    placeholder="صلاحية الكود الى"
                    name="end_date"
                  />
                  <div className="input-icon">
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12">
              <div className="select-reg m-0">
                <InputFiled
                  label={"عدد مرات الأستخدام"}
                  name="uses"
                  type="number"
                  placeholder={"عدد مرات الأستخدام"}
                  success
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="select-reg">
              <SelectField
                name="category_type"
                label={"القسم"}
                options={[
                  { value: "public", label: "خاص" },
                  { value: "private", label: "عام" },
                ]}
                valueSelected={"القسم "}
              />
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="select-reg">
              <SelectField
                name="is_active"
                label={"مفعل"}
                options={[
                  { value: "1", label: "نعم" },
                  { value: "0", label: "لا" },
                ]}
                valueSelected={"مفعل"}
              />
            </div>
          </div>

          <button type="submit" className="main-btn btn-submit px-5 mt-4">
            حفظ
          </button>
        </FormField>
      </div>
    </>
  );
};

export default EditAddDiscountCodes;
