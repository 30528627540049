import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import MultiSelect from "Components/Forms/MultiSelect";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCategories } from "store/general/generalActions";

import {
  addService,
  getBranches,
  getEmployees,
  getSections,
  getService,
  updateService,
} from "store/vendor/vendorActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("اسم الخدمة مطلوب"),
  description_ar: Yup.string().required("وصف الخدمة مطلوب"),
  price: Yup.number().required("سعر الخدمة مطلوب"),
  section_id: Yup.string().required("تبويب الخدمة مطلوب"),
  category_id: Yup.string().required("القسم الرئيسيى مطلوب"),
  // sub_category_id: Yup.string().required("القسم الفرعي مطلوب"),
  featured: Yup.string().required("هل الخدمة مميزة؟ مطلوب"),
  extra_time: Yup.string().required("الوقت الأضافى لاعداد الخدمة مطلوب"),
  service_time: Yup.string().required("الوقت المستغرق لاتمام الخدمة مطلوب"),
  branch_id: Yup.array().min(1).required("الفروع للخدمة مطلوب"),
  employee_id: Yup.array().min(1).required("اختيار الموظفين للخدمة مطلوب"),
});

const actionTypes = [
  {
    name: "#/new-section",
    for: "add new section",
  },
];

const EditAddServicesDetails = () => {
  const [categories, setCategories] = useState([]);
  const [branches, setBranches] = useState([]);
  const [sections, setSections] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [formValues, setFormValues] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { hash: action } = useLocation();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const initialValues = {
    name_ar: "",
    price: "",
    description_ar: "",
    category_id: "",
    featured: "",
    extra_time: "",
    service_time: "",
    branch_id: "",
    employee_id: "",
  };

  const navigate = useNavigate();

  // Add this new state
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (values, { resetForm, setErrors }) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (id) {
      dispatch(
        updateService({
          ...values,
          sub_category_id: values.category_id,
        }),
      )
        .then(unwrapResult)
        .then((res) => {
          toast.success("تم التعديل بنجاح.");
          setIsDataLoading(true);
          getServiceDetails();
          setTimeout(() => {
            navigate("/dashboard/vendor/servicespage");
          }, 1000);
        })
        .catch((error) => {
          setErrors(error.errors);
          console.log(error, "error");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      const formattedData = {
        ...values,
        sub_category_id: values.category_id,
        name_en: "dummy_name_en",
        description_en: "dummy_descriptions_en",
      };
      dispatch(addService(formattedData))
        .then(unwrapResult)
        .then(() => {
          toast.success("تم الاضافة بنجاح.");
          resetForm();
          setTimeout(() => {
            navigate("/dashboard/vendor/servicespage");
          }, 2000);
        })
        .catch((error) => {
          setErrors(error.errors);
          console.log(error, "error");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const fetchCategroies = () => {
    dispatch(getCategories())
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setCategories(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchBranches = () => {
    const params = {
      has: "vendor",
      with: ["vendor"],
      columns: ["vendor_id"],
      operand: ["="],
      column_values: [user.model_id],
    };
    dispatch(getBranches(params))
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name,
          value: cat.id,
        }));
        setBranches(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchSections = () => {
    dispatch(getSections())
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setSections(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getServiceDetails = () => {
    dispatch(getService(id))
      .then(unwrapResult)
      .then((res) => {
        const branch_id = res.data.branches.map((branch) => branch.id);
        const employee_id = res.data.employees.map((branch) => branch.id);
        const updatedData = {
          ...res.data,
          branch_id,
          employee_id,
        };
        setFormValues(updatedData);
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchEmployees = () => {
    const params = {
      has: "vendor",
      with: ["user", "vendor"],
      columns: ["vendor_id"],
      operand: ["="],
      column_values: [user.model_id],
    };
    dispatch(getEmployees(params))
      .then(unwrapResult)
      .then((res) => {
        console.log({ employees: res.data.data });

        setEmployees(res.data.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchCategroies();
    fetchBranches();
    fetchSections();
    fetchEmployees();
    if (id) {
      setIsDataLoading(true);
      getServiceDetails();
    }
  }, [id]);

  return (
    <>
      <HelmetInfo titlePage={"اضافة تعديل بيانات الخدمة"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/vendor/homedashboard"}
          titleInfoPage={"اضافة / تعديل بيانات الخدمة"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/vendor/servicespage">
            عودة
          </Link>
        </div>
      </div>

      <div className="form-edit-services" data-aos="fade-up">
        {isDataLoading ? (
          <Loader />
        ) : (
          <FormField
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            <div className="row g-1 g-sm-3">
              <div className="col-12">
                <InputField
                  name="name_ar"
                  type="text"
                  placeholder={"اسم الخدمة بالعربية"}
                  label={"اسم الخدمة بالعربية"}
                  success
                />
              </div>

              <div className="col-12 col-md-12">
                <InputField
                  name="description_ar"
                  placeholder={"وصف الخدمة بالعربية"}
                  label={"وصف الخدمة بالعربية"}
                  success
                />
              </div>

              <div className="col-12 col-md-6">
                <InputField
                  label={"سعر الخدمة"}
                  name="price"
                  type="number"
                  placeholder={"سعر الخدمة"}
                  success
                />
              </div>
              {action !== actionTypes[0].name && (
                <div className="col-12 col-md-6">
                  <label htmlFor="exampleFormControlInput24" className="form-label">
                    تبويب الخدمة
                  </label>
                  <SelectField
                    name="section_id"
                    label={false}
                    options={sections}
                    valueSelected={"تبويب الخدمة"}
                  />
                </div>
              )}

              <div className="col-12 col-md-6">
                <label htmlFor="exampleFormControlInput25" className="form-label">
                  القسم الرئيسيى
                </label>
                <SelectField
                  name="category_id"
                  label={false}
                  options={categories}
                  valueSelected={"القسم الرئيسيى"}
                />
              </div>
              {/* <div className="col-12 col-md-6">
                <label htmlFor="exampleFormControlInput26" className="form-label">
                  القسم الفرعي
                </label>
                <SelectField
                  name="sub_category_id"
                  label={false}
                  options={categories}
                  valueSelected={"القسم الفرعي"}
                />
              </div> */}
              <div className="col-12 col-md-6">
                <label htmlFor="exampleFormControlInput26" className="form-label">
                  هل الخدمة مميزة؟
                </label>
                <SelectField
                  name="featured"
                  label={false}
                  options={[
                    { value: 1, label: "نعم" },
                    { value: 0, label: "لا" },
                  ]}
                  valueSelected={"هل الخدمة مميزة؟"}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label={"الوقت المستغرق لاتمام الخدمة"}
                  placeholder={"الوقت المستغرق لاتمام الخدمة"}
                  name="service_time"
                  type="number"
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label={"الوقت الأضافى لاعداد الخدمة"}
                  placeholder={"الوقت الأضافى لاعداد الخدمة"}
                  name="extra_time"
                  type="number"
                  success
                />
                {/* <div className="select-reg">
                  
                </div> */}
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="exampleFormControlInput28" className="form-label">
                  الفروع للخدمة
                </label>
                <MultiSelect
                  name="branch_id"
                  label={false}
                  placeholder={"الفروع للخدمة"}
                  options={branches}
                />
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="exampleFormControlInput29" className="form-label">
                  اختيار الموظفين للخدمة
                </label>
                <MultiSelect
                  name="employee_id"
                  label={false}
                  placeholder={"اختيار الموظفين للخدمة"}
                  options={employees.map((employee) => ({
                    value: employee.id,
                    label: `${employee.user.first_name} ${employee.user.last_name}`,
                  }))}
                />
              </div>
            </div>

            <button type="submit" className="main-btn btn-submit px-5 mt-4" disabled={isSubmitting}>
              {isSubmitting ? "جاري الحفظ..." : "حفظ"}
            </button>
          </FormField>
        )}

        {/* ========== END FORM FIELD ========= */}
      </div>
    </>
  );
};

export default EditAddServicesDetails;
