import { useParams } from "react-router-dom";
import ClientsReport from "./ClientsReport";
import EmployeesReport from "./EmployeesReport";
import FinancialReport from "./FinancialReport";
import "./ReportDetails.css";
import OffersReport from "./ReportOffers";
import PackagesReport from "./ReportPackages";
import ProductsReport from "./ReportProducts";
import ServicesReport from "./ReportServices";

const reportsTranslation = {
  services: "تقرير الخدمات",
  offers: "تقرير العروض",
  packages: "تقرير الباقات",
  products: "تقرير المنتجات",
  customers: "تقرير العملاء",
  employees: "تقرير الموظفين",
  financial: "تقرير مالي",
  sales: "تقرير المبيعات",
};

const ReportDetails = () => {
  const { reportId } = useParams();

  const renderReport = () => {
    switch (reportId) {
      case "services":
        return <ServicesReport />;
      case "offers":
        return <OffersReport />;
      case "packages":
        return <PackagesReport />;
      case "products":
        return <ProductsReport />;
      case "customers":
        return <ClientsReport />;
      case "employees":
        return <EmployeesReport />;
      case "financial":
        return <FinancialReport />;
      default:
        return <div>Report not found</div>;
    }
  };
  const initialValues = {
    search: "",
    branch: "",
    duration: "",
    dateFrom: "",
    dateTo: "",
  };
  return (
    <div className="report-details p-3">
      <div className="report-header">
        <div className="report-title mb-5">{reportsTranslation[reportId]}</div>
        {/* <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            console.log(values);
          }}>
          <Form className="report-filters">
            <div className="filter-group" title="التاريخ من">
              <Field name="dateFrom" type="date" placeholder="التاريخ من" />
            </div>
            <div className="filter-group" title="التاريخ إلى">
              <Field name="dateTo" type="date" placeholder="التاريخ إلى" />
            </div>
            <div className="filter-group p-1">
              <Field as="select" name="duration">
                <option value="">المدة</option>
                <option value="1week">أسبوع</option>
                <option value="1month">شهر</option>
              </Field>
            </div>
            <div className="filter-group">
              <Field as="select" name="branch">
                <option value="">الفرع</option>
                <option value="branch1">فرع 1</option>
                <option value="branch2">فرع 2</option>
              </Field>
            </div>

            <div className="report-actions">
              <button type="submit" className="search-btn">
                بحث
              </button>

              <button type="button" className="export-btn">
                تصدير التقرير
              </button>
            </div>
          </Form>
        </Formik> */}
      </div>

      {renderReport()}
    </div>
  );
};

export default ReportDetails;
