import { createSlice } from "@reduxjs/toolkit";
import { statiticsLastCustomers } from "store/admin/adminActions";
import {
  addBranch,
  addEmployee,
  addOffer,
  addService,
  deleteEmployee,
  getBooking,
  getBookingCountChart,
  getBookings,
  getBookingStatisticsMonth,
  getBookingStatisticsWeek,
  getBookingTotalChart,
  getBranch,
  getBranches,
  getEmployee,
  getEmployees,
  getHomeData,
  getOffer,
  getOffers,
  getRegisterStatisticsMonth,
  getSections,
  getService,
  getServices,
  getSubscriptions,
  getVendorCustomer,
  statiticsLastBookings,
  updateBranch,
  updateEmployee,
  updateOffer,
  updateService,
  updateSubscription,
} from "./vendorActions";

const InitialState = {
  isLoading: false,
  hasError: false,
  errorMessage: {},
  branches: [],
  categories: [],
  services: [],
  offers: [],

  vendorSettings: {
    // id: null,
    // stock_alert: null,
    // calender_differance: null,
    // calender_differance_type: null,
    // booking_differance: null,
    // booking_range: null,
    // cancel_booking: null,
    // reschedule_booking: null,
    // vendor_id: null,
    // is_system: null,
    // is_active: null,
  },
  // POS Cart state
  cartDetails: {
    services: [],
    employees: [],
    customers: [],
    payment: {
      method: "online", // online /cash
      total: 0,
      tax: 0,
      discount: 0,
    },
  },
};

const VendorSlice = createSlice({
  name: "vendor",
  initialState: InitialState,
  reducers: {
    setBranches: (state, action) => {
      state.branches = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },

    // cart services
    addServiceToCart: (state, action) => {
      const service = action.payload;
      const doesServiceExist = state.cartDetails.services.some((serv) => serv.serviceId === service.serviceId);
      if (!doesServiceExist) {
        state.cartDetails.services.push(service);
      }
    },

    removeServiceFromCart: (state, action) => {
      const serviceId = action.payload;
      const allServices = state.cartDetails.services.filter((service) => service.serviceId !== serviceId);
      state.cartDetails.services = allServices;
    },

    // cart employees
    addEmployeeToCart: (state, action) => {
      // over writes the employee because only one employee can be selected
      const employee = action.payload;
      state.cartDetails.employees = [employee];
    },
    removeEmployeeFromCart: (state, action) => {
      const employeeId = action.payload;
      const allEmployees = state.cartDetails.employees.filter((employee) => employee.id !== employeeId);
      state.cartDetails.employees = allEmployees;
    },

    // cart customers
    addCustomerToCart: (state, action) => {
      const customer = action.payload;
      const doesCustomerExist = state.cartDetails.customers.some((cust) => cust.id === customer.id);
      if (!doesCustomerExist) {
        state.cartDetails.customers.push(customer);
      }
    },
    removeCustomerFromCart: (state, action) => {
      const customerId = action.payload;
      const allCustomers = state.cartDetails.customers.filter((customer) => customer.id !== customerId);
      state.cartDetails.customers = allCustomers;
    },

    // cart payment method
    setPaymentMethod: (state, action) => {
      state.cartDetails.payment.method = action.payload;
    },
    setPaymentTotal: (state, action) => {
      state.cartDetails.payment.total = action.payload;
    },

    setPaymentTax: (state, action) => {
      state.cartDetails.payment.tax = action.payload;
    },

    setPaymentDiscount: (state, action) => {
      state.cartDetails.payment.discount = action.payload;
    },
    // cart offers
    setOffers: (state, action) => {
      state.offers = action.payload;
    },
    clearCart: (state) => {
      state.cartDetails = {
        services: [],
        employees: [],
        customers: [],
        payment: {
          method: "online",
          total: 0,
        },
      };
    },
    setVendorSettings: (state, action) => {
      state.vendorSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getEmployees.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getEmployees.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getServices.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getServices.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getServices.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(updateService.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateService.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateService.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(addService.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addService.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(addService.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBranches.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBranches.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBranches.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getSections.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getSections.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getSections.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(updateEmployee.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateEmployee.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(addEmployee.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(addEmployee.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getEmployee.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getEmployee.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(deleteEmployee.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(deleteEmployee.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getService.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getService.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getService.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBranch.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBranch.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBranch.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(updateBranch.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateBranch.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateBranch.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(addBranch.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addBranch.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(addBranch.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getOffers.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getOffers.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getOffers.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getOffer.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getOffer.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getOffer.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(addOffer.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addOffer.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(addOffer.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(updateOffer.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateOffer.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateOffer.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getSubscriptions.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getSubscriptions.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getSubscriptions.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateSubscription.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateSubscription.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getHomeData.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getHomeData.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getHomeData.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBookingCountChart.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBookingCountChart.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBookingCountChart.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBookingTotalChart.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBookingTotalChart.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBookingTotalChart.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBookingStatisticsMonth.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBookingStatisticsMonth.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBookingStatisticsMonth.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBookingStatisticsWeek.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBookingStatisticsWeek.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBookingStatisticsWeek.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getRegisterStatisticsMonth.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getRegisterStatisticsMonth.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getRegisterStatisticsMonth.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(statiticsLastBookings.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(statiticsLastBookings.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(statiticsLastBookings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(statiticsLastCustomers.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(statiticsLastCustomers.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(statiticsLastCustomers.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBookings.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBookings.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBookings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBooking.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBooking.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBooking.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getVendorCustomer.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getVendorCustomer.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getVendorCustomer.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export const {
  setBranches,
  setCategories,
  setServices,
  addCustomerToCart,
  addEmployeeToCart,
  addServiceToCart,

  removeCustomerFromCart,
  removeEmployeeFromCart,
  removeServiceFromCart,
  setPaymentMethod,
  clearCart,
  setPaymentTotal,
  setPaymentTax,
  setPaymentDiscount,
  setOffers,

  setVendorSettings,
} = VendorSlice.actions;
export default VendorSlice.reducer;
