import { unwrapResult } from "@reduxjs/toolkit";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCategories } from "store/general/generalActions";
import { getBranches, getOffers, getServices } from "store/vendor/vendorActions";
import { setBranches, setCategories, setOffers, setServices } from "store/vendor/vendorSlice";
import Footer from "../Footer/Footer";
import NavBarDashboard from "../NavBarDashboard/NavBarDashboard";
import SideNavBar from "../SideNavBar/SideNavBar";

const LayoutDasboard = () => {
  const user = useGetAuthUser();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    categories,
    services: vendorServices,
    offers: vendorOffers,
    branches,
  } = useSelector((state) => state.vendor);

  useEffect(() => {
    localStorage.setItem("sidebarOpen", true);
  }, []);

  const [isOpen, setIsOpen] = useState(() =>
    JSON.parse(localStorage.getItem("sidebarOpen") === "true" ? "true" : "false"),
  );

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.type === "vendor" && location.pathname.includes("admin")) {
      navigate("/dashboard/vendor/homedashboard", { replace: true });
    }
  }, [location, navigate]);

  const fetchData = useCallback(
    async (action, params, setter) => {
      try {
        const res = await dispatch(action(params)).then(unwrapResult);
        dispatch(setter(res.data.data));
      } catch (err) {
        console.error(err);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (categories.length === 0) {
      fetchData(getCategories, {}, setCategories);
    }
  }, [categories.length, fetchData]);

  useEffect(() => {
    if (user?.type === "vendor" && vendorServices.length === 0) {
      fetchData(
        getServices,
        {
          columns: ["is_active"],
          operand: ["="],
          column_values: ["1"],
        },
        (data) => setServices(data.filter((item) => item.vendor_id === user?.model_id)),
      );
    }
  }, [vendorServices.length, fetchData, user?.model_id]);

  useEffect(() => {
    if (user?.type === "vendor" && vendorOffers.length === 0) {
      fetchData(
        getOffers,
        {
          has: "vendor",
          with: ["services"],
          columns: ["is_active", "vendor_id"],
          operand: ["=", "="],
          column_values: ["1", user?.model_id],
        },
        setOffers,
      );
    }
  }, [vendorOffers.length, fetchData, user?.model_id]);

  useEffect(() => {
    if (user?.type === "vendor" && branches.length === 0) {
      fetchData(
        getBranches,
        {
          columns: ["is_active", "vendor_id"],
          operand: ["=", "="],
          column_values: ["1", user?.model_id],
        },
        setBranches,
      );
    }
  }, [branches.length, fetchData, user?.model_id]);

  useEffect(() => {
    localStorage.setItem("sidebarOpen", JSON.stringify(isOpen));
  }, [isOpen]);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const closeToggleSidebar = useCallback(() => {
    if (window.innerWidth <= 991) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", closeToggleSidebar);
    return () => window.removeEventListener("resize", closeToggleSidebar);
  }, [closeToggleSidebar]);

  useEffect(() => {
    if (navigator.userAgent.includes("Firefox")) {
      document.body.classList.add("firefox");
    }
  }, []);

  return (
    <div className="d-flex">
      <SideNavBar isOpen={isOpen} closeToggleSidebar={closeToggleSidebar} />
      <div className={`contnet-page d-flex flex-column vh-100 ${isOpen ? "activeOpen" : ""}`}>
        <NavBarDashboard toggleSidebar={toggleSidebar} />
        <div className="px-3 pb-4">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LayoutDasboard;
