import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOffersReport } from "../../../store/vendor/vendorActions";
import "./ReportDetails.css";

const OffersReport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [allBookings, setAllBookings] = useState([]);
  const [topOffers, setTopOffers] = useState({});

  useEffect(() => {
    const offerMap = new Map(offers.map((offer) => [offer.id, offer]));

    const offerCounts = new Map();
    allBookings.forEach((booking) => {
      const count = (offerCounts.get(booking.offer_id) || 0) + 1;
      offerCounts.set(booking.offer_id, count);
    });

    const topOffersArray = Array.from(offerCounts.entries())
      .map(([offerId, count]) => {
        const offer = offerMap.get(offerId);
        return {
          name: offer?.name_ar || "Unknown",
          bookings: count,
        };
      })
      .filter((x) => x.name !== "Unknown")
      .sort((a, b) => b.bookings - a.bookings)
      .slice(0, 5);

    setTopOffers(topOffersArray);
  }, [offers, allBookings]);

  useEffect(() => {
    dispatch(
      getOffersReport({
        with: ["bookings"],
        per_page: 100,
        dateFrom: "2024-01-01",
        dateTo: "2024-02-31",
      }),
    )
      .then(unwrapResult)
      .then((res) => {
        setOffers(res.data.data);
        setAllBookings(res.data.data[0].vendor.bookings);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const dummyData = {
    totalOffers: offers.length,
    mostRequestedOffers: topOffers,
  };

  if (isLoading) return <Loader />;
  return (
    <div className="offers-report">
      <div className="report-summary">
        <div className="summary-card">
          <div className="card-content">
            <h3>{dummyData.totalOffers}</h3>
            <p>عدد العروض</p>
          </div>
        </div>
      </div>

      <div className="report-table">
        <h3 className="mb-4">العروض الأكثر طلبا</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>العرض</th>
              <th>عدد الحجوزات</th>
            </tr>
          </thead>
          <tbody>
            {dummyData.mostRequestedOffers.map((offer, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{offer.name}</td>
                <td>{offer.bookings}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OffersReport;
